import { UserModel } from "../../../../product/models/user/userModel";
import { IProfileRemoteDataSource } from "./IProfileRemoteDataSource";
import { inject, injectable } from "inversify";
import type { INetworkManager } from "next-netkit";
import { RequestMethod } from "next-netkit";
import { APIConst } from "../../../../product/constants/apiConst";
import { NewsModel } from "../../../../product/models/news/newsModel";

@injectable()
export class ProfileRemoteDataSource implements IProfileRemoteDataSource {
  @inject("INetworkManager")
  private networkManager!: INetworkManager;

  getProfile(userId: number): Promise<UserModel> {
    return this.networkManager.request<UserModel>({
      method: RequestMethod.GET,
      url: APIConst.getProfile(userId),
    });
  }

  getCurrentProfile(): Promise<UserModel> {
    return this.networkManager.request<UserModel>({
      method: RequestMethod.GET,
      url: APIConst.PROFILE,
    });
  }

  getNewsByUserId(userId: number, page: number): Promise<NewsModel[]> {
    return this.networkManager.requestList<NewsModel>({
      method: RequestMethod.GET,
      url: APIConst.newsByUserId({ userId, page }),
    });
  }

  followUser(userId: number): Promise<void> {
    return this.networkManager.request({
      method: RequestMethod.POST,
      url: APIConst.followUnfollow(userId),
    });
  }

  unfollowUser(userId: number): Promise<void> {
    return this.networkManager.request({
      method: RequestMethod.DELETE,
      url: APIConst.followUnfollow(userId),
    });
  }

  getFollowersByUserId(userId: number, page: number): Promise<UserModel[]> {
    return this.networkManager.requestList<UserModel>({
      method: RequestMethod.GET,
      url: APIConst.followers({ userId, page }),
    });
  }

  getFollowingsByUserId(userId: number, page: number): Promise<UserModel[]> {
    return this.networkManager.requestList<UserModel>({
      method: RequestMethod.GET,
      url: APIConst.followings({ userId, page }),
    });
  }

  removeUserImage(): Promise<void> {
    return this.networkManager.request({
      method: RequestMethod.DELETE,
      url: APIConst.USER_IMAGE,
    });
  }

  getBookmarkedNews(page: number): Promise<NewsModel[]> {
    return this.networkManager.requestList<NewsModel>({
      method: RequestMethod.GET,
      url: APIConst.bookmarks(page),
    });
  }

  suggestedUsersToFollow(): Promise<UserModel[]> {
    return this.networkManager.requestList<UserModel>({
      method: RequestMethod.GET,
      url: APIConst.SUGGESTED_USERS_TO_FOLLOW,
    });
  }
}
