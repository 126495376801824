import { ContainerModule, interfaces } from "inversify";
import { ProfileContainerTypes } from "../containerTypes/profileContainerTypes";
import { IProfileRemoteDataSource } from "../../../features/profile/data/dataSources/IProfileRemoteDataSource";
import { ProfileRemoteDataSource } from "../../../features/profile/data/dataSources/ProfileRemoteDataSource";
import { IProfileRepository } from "../../../features/profile/domain/repositories/IProfileRepository";
import { ProfileRepository } from "../../../features/profile/data/repositories/ProfileRepository";
import { FollowUser } from "../../../features/profile/domain/useCases/FollowUser";
import { GetBookmarkedNews } from "../../../features/profile/domain/useCases/GetBookmarkedNews";
import { GetFollowersByUserId } from "../../../features/profile/domain/useCases/GetFollowersByUserId";
import { GetFollowingsByUserId } from "../../../features/profile/domain/useCases/GetFollowingsByUserId";
import { GetNewsByUserId } from "../../../features/profile/domain/useCases/GetNewsByUserId";
import { GetUserProfile } from "../../../features/profile/domain/useCases/GetUserProfile";
import { RemoveUserImage } from "../../../features/profile/domain/useCases/RemoveUserImage";
import { UnfollowUser } from "../../../features/profile/domain/useCases/UnfollowUser";
import { SuggestedUsersToFollow } from "../../../features/profile/domain/useCases/SuggestedUsersToFollow";

const profileContainer = new ContainerModule((bind: interfaces.Bind) => {
  bind<IProfileRemoteDataSource>(ProfileContainerTypes.IProfileRemoteDataSource).to(ProfileRemoteDataSource);
  bind<IProfileRepository>(ProfileContainerTypes.IProfileRepository).to(ProfileRepository);
  bind<FollowUser>(ProfileContainerTypes.FollowUser).to(FollowUser);
  bind<UnfollowUser>(ProfileContainerTypes.UnfollowUser).to(UnfollowUser);
  bind<GetBookmarkedNews>(ProfileContainerTypes.GetBookmarkedNews).to(GetBookmarkedNews);
  bind<GetFollowersByUserId>(ProfileContainerTypes.GetFollowersByUserId).to(GetFollowersByUserId);
  bind<GetFollowingsByUserId>(ProfileContainerTypes.GetFollowingsByUserId).to(GetFollowingsByUserId);
  bind<GetNewsByUserId>(ProfileContainerTypes.GetNewsByUserId).to(GetNewsByUserId);
  bind<GetUserProfile>(ProfileContainerTypes.GetUserProfile).to(GetUserProfile);
  bind<RemoveUserImage>(ProfileContainerTypes.RemoveUserImage).to(RemoveUserImage);
  bind<SuggestedUsersToFollow>(ProfileContainerTypes.SuggestedUsersToFollow).to(SuggestedUsersToFollow);
});

export { profileContainer };
