import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useAppDispatch, useAppSelector } from "../../../stores/hooks";
import { followUser, getUserById, getUserNews, unfollowUser } from "../../../stores/profile/ProfileThunks";
import ProfileNewsCard from "./profileNewsCard/ProfileNewsCard";
import PopularTopicsBlock from "../feed/popularTopics/PopularTopicsBlock";
import SuggestedUsersList from "./SuggestedUsers/SuggestedUsersList";
import "./otherProfile.css";
import UrlHelper from "../../../product/utils/dateTime/GetIdFromCurrentUrl";
import DateTimeUtil from "../../../product/utils/dateTime/DateTimeUtil";
import ProfileFollowButton from "./buttons/ProfileFollowButton";

const OtherProfileV2: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userAppDispatch = useAppDispatch();
  const user_profile: string = require("../../../product/assets/icons/user_profile.svg").default;
  const profileBackground: string = require("../../../product/assets/images/bg-user-profile.svg").default;
  const locationIco: string = require("../../../product/assets/icons/location.svg").default;
  const joinDateIco: string = require("../../../product/assets/icons/join-date.svg").default;
  const noProfileUser: string = require("../../../product/assets/noprofileuser.png");

  // Redux State
  const { userNews, currentUser, loading, error, isFollowed, followersCount, followingsCount } = useAppSelector(
    (state) => state.profile
  );

  const [userPageId, setUserPageId] = useState<number | undefined>(undefined);

  const imgRef = useRef<HTMLImageElement | null>(null);
  const fallbackUrl = require("../../../product/assets/icons/user_profile.svg").default;

  const loaderProfile: string = require("../../../product/assets/loaders/profile.svg").default;

  const urlHelper = new UrlHelper();

  const onImageError = () => {
    if (imgRef.current) {
      imgRef.current.src = fallbackUrl;
    }
  };

  const handleFollowUser = () => {
    isFollowed ? userAppDispatch(unfollowUser(userPageId)) : userAppDispatch(followUser(userPageId));
  };

  useEffect(() => {
    const id = urlHelper.getIdFromCurrentUrl();
    if (id !== undefined) {
      setUserPageId(id);

      // Fetch user data
      const profileParams = {
        userId: id,
        localUser: false,
      };
      dispatch(getUserById({ profileParams }));

      const page = 1;
      dispatch(getUserNews({ userId: id, page }));
    } else {
      navigate("/");
    }
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (currentUser === undefined || error) {
    return <div>Error occurred</div>;
  }
  return (
    <div className='notifications-container'>
      <div className='notifications-content'>
        <div className='page-notifications'>
          <div className='profile-heading'>
            <section className='profile-cnts'>
              {loading ? (
                <div>
                  <img src={loaderProfile} className='load-other-profile' alt='Loading' />
                </div>
              ) : (
                <div className='block-content-profile'>
                  {/* Profile Background */}
                  <div
                    className='profile-heading-my'
                    style={{
                      background: `url(${require("../../../product/assets/images/bg-user-profile.svg").default})`,
                    }}
                  ></div>

                  {/* Profile Image */}
                  <div className='image-profile'>
                    <img
                      ref={imgRef}
                      src={currentUser.imageUrl || fallbackUrl}
                      alt='avatar'
                      className='poster-img-intro-my'
                      onError={onImageError}
                    />
                  </div>

                  {/* Profile Info */}
                  <div className='my-user-inf'>
                    <div className='post-head-left-intro-my'>
                      <div className='single-post-user-intro-my'>
                        <span className='name-surname-intro-my'>{currentUser.fullName || "Anonymous"}</span>
                        <span className='usr-name-intro-my'>@{currentUser.nickname || "unknown"}</span>
                      </div>
                    </div>

                    <ProfileFollowButton onFollowToggled={handleFollowUser} />
                  </div>

                  <div className='my-location-box'>
                    <span className='class-kz'> {currentUser?.slogan} </span>
                    <div className='location-text'>
                      <img src={locationIco} alt='' />
                      <span>{currentUser?.location}</span>
                    </div>
                    <div className='join-date-text'>
                      <img src={joinDateIco} alt='' />
                      <span>
                        {currentUser && currentUser.createdAt
                          ? DateTimeUtil.parseCreatedAt(currentUser.createdAt)
                          : "..."}
                      </span>
                    </div>
                    <div className='flwrs'>
                      <Link to='/followers' className='flwrs-count'>
                        {" "}
                        <strong className='text-white'>{currentUser?.followersCountValue}</strong>{" "}
                        <span className='gray-text right-10'> Takipçi</span>{" "}
                      </Link>
                      <Link to='/followers' className='flwrs-count left-20'>
                        {" "}
                        <strong className='text-white'>{currentUser?.followingsCountValue} </strong>{" "}
                        <span className='gray-text'> Takip</span>{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </section>
          </div>

          <Tabs>
            <TabList>
              <Tab>Jurnaller</Tab>
              {/*<Tab>Doğrulamalar</Tab>*/}
              {/*<Tab>Yalanlananlar</Tab>*/}
              <Tab>Hakkında</Tab>
            </TabList>

            <TabPanel>
              {userNews.map((item) => (
                <ProfileNewsCard key={item.id} news={item} />
              ))}
            </TabPanel>
            {/*<TabPanel>Doğrulamalar Tab Content</TabPanel>*/}
            {/*<TabPanel>Yalanlananlar Tab Content</TabPanel>*/}
            <TabPanel>Hakkında Tab Content</TabPanel>
          </Tabs>
        </div>
      </div>

      <div className='right-sb2'>
        <PopularTopicsBlock />
        <SuggestedUsersList />
      </div>
    </div>
  );
};

export default OtherProfileV2;
