export enum NewsValidationEnum {
  Verified = 'VERIFIED',
  Denied = 'DENIED',
  None = 'NONE',
}

export class NewsValidationEnumExtension {
  static value(enumValue: NewsValidationEnum): string {
    return enumValue;
  }
}