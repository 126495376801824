import "reflect-metadata";
import { inject, injectable } from "inversify";
import type { IAuthRepository } from "../repositories/auth.repository.interface";
import { ResultFutureVoid } from "../../../../core/types/types";
import { AuthContainerTypes } from "../../../../product/di_containers/containerTypes/autContainerTypes";

@injectable()
export class CheckIsAuthenticatedUseCase {
  @inject(AuthContainerTypes.IAuthRepository)
  private authRepo!: IAuthRepository;

  execute(): ResultFutureVoid {
    return this.authRepo.isAuthenticated();
  }
}
