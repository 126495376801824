import React from "react";
import { Outlet } from "react-router-dom";
import SideBarV2 from "../../../../product/components/sidebar/SideBarV2";
import HeaderV2 from "../../../../product/components/header/HeaderV2";
import "./MainLayout.css";
import MobileMenu from "../../mobileMenu/MobileMenu";

const MainLayout: React.FC = () => {
  return (
    <>  
    <div className='main-layout-app-container'>
      <SideBarV2 />
      <div className='main-layout-content'>
        <HeaderV2 />
        <div className='layout-content'>
          <Outlet />
        </div>
      </div>
      <MobileMenu />
    </div>
</>

  );
};

export default MainLayout;
