import { ICategoryRepository } from "../../domain/repositories/ICategoryRepository";
import { ResultFuture } from "../../../../../core/types/types";
import { CategoryModel } from "../../../../../product/models/category/categoryModel";
import { Left, Right } from "../../../../../core/types/either";
import { ApiException } from "next-netkit";
import { ApiFailure } from "../../../../../core/error/failure";
import { inject, injectable } from "inversify";
import type { ICategoryRemoteDataSource } from "../dataSources/ICategoryRemoteDataSource";
import type { ICategoryLocalDataSource } from "../dataSources/ICategoryLocalDataSource";
import { CategoryTypes } from "../../categoryTypes";

@injectable()
export class CategoryRepository implements ICategoryRepository {
  @inject(CategoryTypes.ICategoryRemoteDataSource)
  private remoteDataSource!: ICategoryRemoteDataSource;

  @inject(CategoryTypes.ICategoryLocalDataSource)
  private localDataSource!: ICategoryLocalDataSource;

  async getUserCategories(): ResultFuture<CategoryModel[]> {
    try {
      const categories = <CategoryModel[]>[];

      // Try to get categories from local storage
      const localCategories = this.localDataSource.getUserCategories();
      if (localCategories.length > 1) {
        return new Right(localCategories);
      }

      // If local storage is empty, fetch from remote source
      const remoteCategories = await this.remoteDataSource.getSelectedCategories();
      remoteCategories.forEach((category) => {
        if (category.category) {
          categories.push(category.category);
        }
      });
      // Save fetched categories to local storage
      this.localDataSource.saveUserCategories(categories);

      return new Right(categories);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async getAllCategories(): ResultFuture<CategoryModel[]> {
    try {
      const categories = <CategoryModel[]>[];

      // Try to get categories from local storage
      const localCategories: CategoryModel[] = this.localDataSource.getAllCategories();
      if (localCategories.length > 1) {
        return new Right(localCategories);
      }

      // If local storage is empty, fetch from remote source
      const remoteCategories: CategoryModel[] = await this.remoteDataSource.getAllCategories();
      remoteCategories.forEach((category) => {
        if (category.id) {
          categories.push(category);
        }
      });
      // Save fetched categories to local storage
      this.localDataSource.saveAllCategories(categories);

      return new Right(categories);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }
}
