const NewsContainerTypes = {
  INewsRemoteDataSource: Symbol.for("INewsRemoteDataSource"),
  INewsRepository: Symbol.for("INewsRepository"),
  CreateNews: Symbol.for("CreateNews"),
  GetFeedNews: Symbol.for("GetFeedNews"),
  GetDeniedNews: Symbol.for("GetDeniedNews"),
  GetVerifiedNews: Symbol.for("GetVerifiedNews"),
  GetNewsDetails: Symbol.for("GetNewsDetails"),
  VerifyNews: Symbol.for("VerifyNews"),
  DenyNews: Symbol.for("DenyNews"),
  BookmarkNews: Symbol.for("BookmarkNews"),
  UnBookmarkNews: Symbol.for("UnBookmarkNews"),
  ShareNews: Symbol.for("ShareNews"),
};

export { NewsContainerTypes };
