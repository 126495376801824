import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NewsModel } from "../../../product/models/news/newsModel";
import { NewsDetailsState } from "./newsDetailsState";
import { denyNews, fetchNewsDetails, shareNews, toggleBookmark, verifyNews } from "./newsDetailsThunks";

const initialState: NewsDetailsState = {
  news: null,
  loading: true,
  error: null,
  isVerified: false,
  isDenied: false,
  isBookmarked: false,
  denialCount: 0,
  verificationCount: 0,
};

export const resetNewsDetailsState = createAction("news/resetNewsDetailsState");

const newsDetailsSlice = createSlice({
  name: "newsDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getNewsDetails();
    verifyNewsReducer();
    denyNewsReducer();
    toggleBookmarkNewsReducer();
    shareNewsReducer();

    builder.addCase(resetNewsDetailsState, (state) => {
      Object.assign(state, initialState);
    });

    function getNewsDetails() {
      builder.addCase(fetchNewsDetails.fulfilled, (state, action: PayloadAction<NewsModel>) => {
        state.loading = false;
        const news: NewsModel = action.payload;
        if (state.news === null) {
          state.news = news;
        }
        state.isBookmarked = news.isBookmarked;
        state.isVerified = news.isVerified;
        state.isDenied = news.isDenied;
        state.denialCount = news.getDenialCount;
        state.verificationCount = news.getVerificationCount;
      });
      builder.addCase(fetchNewsDetails.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.loading = false;
        state.error = action.payload ?? "An unknown error occurred";
      });
    }

    function verifyNewsReducer() {
      builder.addCase(verifyNews.fulfilled, (state, action: PayloadAction<boolean>) => {
        // Checking if previously verified or not. If yes, then decrement the count, else increment.
        state.isVerified ? state.verificationCount-- : state.verificationCount++;
        // Toggle the verification status
        state.isVerified = !state.isVerified;

        if (state.isDenied) {
          state.isDenied = false;
          state.denialCount--;
        }
      });
      builder.addCase(verifyNews.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.loading = false;
        state.error = action.payload ?? "An unknown error occurred";
      });
    }

    function denyNewsReducer() {
      // Deny news
      builder.addCase(denyNews.fulfilled, (state, action: PayloadAction<boolean>) => {
        // Checking if previously denied or not. If yes, then decrement the count, else increment.
        state.isDenied ? state.denialCount-- : state.denialCount++;
        // Toggle the denial status
        state.isDenied = !state.isDenied;

        if (state.isVerified) {
          state.isVerified = false;
          state.verificationCount--;
        }
      });

      builder.addCase(denyNews.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.loading = false;
        state.error = action.payload ?? "An unknown error occurred";
      });
    }

    function toggleBookmarkNewsReducer() {
      // Bookmark news
      builder.addCase(toggleBookmark.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload ?? "An unknown error occurred";
      });
      builder.addCase(toggleBookmark.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.isBookmarked = action.payload;
      });
    }

    function shareNewsReducer() {
      // Share news
      builder.addCase(shareNews.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload ?? "An unknown error occurred";
      });
    }
  },
});

export default newsDetailsSlice.reducer;
