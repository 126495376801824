import React from "react";
import "./ActionButtonRow.css";
import { ReactComponent as CommentIcon } from "../../../../product/assets/iconsV2/outlined/comment.svg";
import { ReactComponent as EyeIcon } from "../../../../product/assets/iconsV2/outlined/eye_open.svg";
import { ReactComponent as FilledVerificationIcon } from "../../../../product/assets/iconsV2/filled/verify.svg";
import { ReactComponent as OutlinedVerificationIcon } from "../../../../product/assets/iconsV2/outlined/verify.svg";
import { ReactComponent as FilledDenyIcon } from "../../../../product/assets/iconsV2/filled/deny.svg";
import { ReactComponent as OutlinedDenyIcon } from "../../../../product/assets/iconsV2/outlined/deny.svg";
import { ReactComponent as Bookmark } from "../../../../product/assets/iconsV2/outlined/bookmark.svg";
import { ReactComponent as BookmarkFilled } from "../../../../product/assets/iconsV2/filled/bookmark.svg";
import ShareSocial from "../../../../components/share/share_social";
import {
  denyNews,
  toggleBookmark,
  verifyNews,
} from "../../../../stores/news/newsDetails/newsDetailsThunks";
import { useAppDispatch } from "../../../../stores/hooks";

interface ActionButtonRowProps {
  url: string | undefined;
  newsId: number | undefined;
  verificationCount?: number;
  denialCount?: number;
  commentCount?: number;
  shareCount?: number;
  viewCount: number | undefined;
  isVerified?: boolean;
  isDenied?: boolean;
  isBookmarked: boolean | false;
  newsTitle: string | undefined;
}

const ActionButtonRow: React.FC<ActionButtonRowProps> = ({
  newsId,
  verificationCount,
  denialCount,
  commentCount,
  isVerified,
  isDenied,
  isBookmarked,
  newsTitle,
  viewCount,
  url,
}) => {
  const dispatch = useAppDispatch();

  const handleVerifyClick = () => {
    dispatch(verifyNews(newsId));
  };

  const handleDenyClick = () => {
    dispatch(denyNews(newsId));
  };

  const handleBookmarkClick = () => {
    dispatch(toggleBookmark(newsId));
  };
  return (
    <div className='action-button-row'>
      <div className='action-button-row-left'>
        <span className='news-details-button-group' onClick={handleVerifyClick}>
          {isVerified ? <FilledVerificationIcon className='verified-icon' /> : <OutlinedVerificationIcon />}
          <div className='news-details-counts'>{verificationCount}</div>
        </span>
        <span className='news-details-button-group' onClick={handleDenyClick}>
          {isDenied ? <FilledDenyIcon className='denied-icon' /> : <OutlinedDenyIcon />}
          <div className='news-details-counts'>{denialCount}</div>
        </span>
        <span className='news-details-icon-group'>
          <CommentIcon />
          <div className='news-details-counts'>{commentCount}</div>
        </span>{" "}
        <span className='news-details-icon-group'>
          <EyeIcon />
          <div className='news-details-counts'>{viewCount}</div>
        </span>
      </div>
      <div className='action-button-row-right'>
        <span className='news-details-button-group'>
          <ShareSocial title={newsTitle || ""} url={url || ""} />
        </span>
        <span className='news-details-button-group' onClick={handleBookmarkClick}>{isBookmarked ? <BookmarkFilled /> : <Bookmark />}</span>
      </div>
    </div>
  );
};

export default ActionButtonRow;
