import { inject, injectable } from "inversify";
import { ResultFuture } from "../../../../core/types/types";
import type { IProfileRepository } from "../repositories/IProfileRepository";
import { UserModel } from "../../../../product/models/user/userModel";
import { ProfileContainerTypes } from "../../../../product/di_containers/containerTypes/profileContainerTypes";

@injectable()
export class GetUserProfile {
  @inject(ProfileContainerTypes.IProfileRepository)
  private profileRepository!: IProfileRepository;

  async execute(params: GetProfileParams): ResultFuture<UserModel> {
    return this.profileRepository.getProfile(params);
  }
}

export class GetProfileParams {
  public userId?: number | undefined;
  public localUser: boolean;

  constructor({ userId, localUser }: { userId: number | undefined; localUser: boolean }) {
    this.userId = userId;
    this.localUser = localUser;
  }
}
