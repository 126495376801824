import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BaseElevatedButton from "../../../components/buttons/elevatedButton/BaseElevatedButton";
import { useAppDispatch } from "../../../stores/hooks";
import BaseInput from "../../../components/inputs/BaseInput";
import { useSelector } from "react-redux";
import BaseOutlinedButton from "../../../components/buttons/outlinedButton/BaseOutlinedButton";
import Footer from "../../../components/footer/Footer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./RemoveAccount.css";
import { RootState } from "../../../stores";
import { removeUserAccount } from "../../../stores/auth/authSlice";
import { AuthStatus } from "../../../stores/auth/AuthStatus";
import { SignInDto } from "../../../features/auth/data/dto/sign-in.dto";

const RemoveAccount: React.FC = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const AppStore: string = require("../../../product/assets/AppStore.svg").default;
  const GooglePlay: string = require("../../../product/assets/GooglePlay.svg").default;
  const { status, error, loading } = useSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    const removeByCredentialsRequest: SignInDto = { email, password, deviceType: "web" };
    try {
      const resultAction = await dispatch(removeUserAccount(removeByCredentialsRequest));
      if (removeUserAccount.fulfilled.match(resultAction)) {
        // Successfully removed account. Redirect to account removed page
        navigate("/account-removed");
      } else {
      }
    } catch (error) {
      toast.error("Bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };

  useEffect(() => {
    if (status === AuthStatus.UserRemoved) {
      navigate("/account-removed");
    }
    if (error) {
      toast.error("Şifre veya e-mail hatalı. Lütfen tekrar deneyin.");
    }
  }, [status, navigate, error]);

  // Function to redirect to App Store
  const handleAppStoreClick = () => {
    window.open("https://apps.apple.com/app/jurnalle/id6466410768", "_blank");
  };

  // Function to redirect to Google Play
  const handleGooglePlayClick = () => {
    window.open("https://play.google.com/store/apps/details?id=com.jurnalle.jurnalle", "_blank");
  };
  return (
    <>
      <div className='remove-account-container row'>
        <div className='remove-account-logo-container'>
          <h1 className='remove-account-logo-text'>jurnalle</h1>
        </div>
        <div className='remove-account-content'>
          <div className='remove-account-content-inner'>
            <h2 className='delete-account-title'>Hesabı sil</h2>
            <form className='remove-account-form-container' onSubmit={handleSubmit}>
              <BaseInput
                label='E-mail'
                type='email'
                id='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <BaseInput
                label='Şifre'
                type='password'
                id='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <div className='sign-in-result-text'>
                {loading && <p className={"remove-account-loading"}>Yükleniyor</p>}
                {error && <p className='error-message'>{error}</p>}
              </div>
              <div className='remove-account-button-group'>
                <BaseOutlinedButton href='https://www.jurnalle.com'>Vazgeç</BaseOutlinedButton>
                <BaseElevatedButton type={loading ? "button" : "submit"} disabled={loading}>
                  {loading ? "Yükleniyor" : "Hesabı sil"}
                </BaseElevatedButton>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RemoveAccount;
