export class CreateNewsParams {
  public title: string;
  public descriptions: string[];
  public referenceUrl: string | null;
  public commentEnabled: boolean;
  public watermarkEnabled: boolean;
  public categories: number[];
  public locationId: number | null;
  public files: File[];

  constructor({
    title,
    descriptions,
    referenceUrl,
    commentEnabled,
    watermarkEnabled,
    categories,
    locationId,
    files,
  }: {
    title: string;
    descriptions: string[];
    referenceUrl: string;
    commentEnabled: boolean;
    watermarkEnabled: boolean;
    categories: number[];
    locationId: number | null;
    files: File[];
  }) {
    this.title = title;
    this.descriptions = descriptions;
    this.referenceUrl = referenceUrl;
    this.commentEnabled = commentEnabled;
    this.watermarkEnabled = watermarkEnabled;
    this.categories = categories;
    this.locationId = locationId;
    this.files = files;
  }
}
