import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProfileState } from "./ProfileState";
import {
  bookmarkNews,
  followUser,
  getUserById,
  getUserNews,
  refreshProfileNews,
  suggestedUsersToFollow,
  unBookmarkNews,
  unfollowUser,
} from "./ProfileThunks";

const initialState: ProfileState = {
  userNews: [],
  suggestedUsers: [],
  currentPage: 1,
  isCurrentLastPage: false,
  loading: true,
  error: undefined,
  currentUser: undefined,
  isLocalUser: false,
  isProBannerDismissed: false,
  isFollowed: false,
  followersCount: 0,
  followingsCount: 0,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetProfile: (state) => {
      state.userNews = [];
      state.currentPage = 1;
      state.isCurrentLastPage = false;
    },
    incrementPage: (state) => {
      state.currentPage += 1;
    },
    setIsCurrentLastPage: (state, action: PayloadAction<boolean>) => {
      state.isCurrentLastPage = action.payload;
    },
    updateProfileImage: (state, action: PayloadAction<string>) => {
      if (state.currentUser) {
        state.currentUser.imageUrl = action.payload;
      }
    },
    updateProfileData: (state, action: PayloadAction<any>) => {
      state.currentUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserById.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.loading = false;
        state.followersCount = state.currentUser.followingsCountValue;
        state.followingsCount = state.currentUser.followersCountValue;
        state.isFollowed = state.currentUser.isFollowed;
      })
      .addCase(getUserById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(followUser.fulfilled, (state, action) => {
        if (state.currentUser && state.currentUser?.id === action.payload) {
          state.currentUser.setIsFollowed = true;
          state.isFollowed = true;
          state.currentUser.increaseFollowersCount();
        } else {
          state.suggestedUsers = state.suggestedUsers.map((user) => {
            if (user.id === action.payload) {
              user.setIsFollowed = true;
            }
            return user;
          });
        }
      })
      .addCase(followUser.rejected, (state, action) => {
        if (state.currentUser && state.currentUser?.id === action.payload) {
          state.currentUser.setIsFollowed = true;
          state.isFollowed = true;
        } else {
          state.suggestedUsers = state.suggestedUsers.map((user) => {
            if (user.id === action.payload) {
              user.setIsFollowed = true;
            }
            return user;
          });
        }
      })
      .addCase(unfollowUser.fulfilled, (state, action) => {
        if (state.currentUser && state.currentUser?.id === action.payload) {
          state.currentUser.setIsFollowed = false;
          state.isFollowed = false;
          state.currentUser.decreaseFollowersCount();
        } else {
          state.suggestedUsers = state.suggestedUsers.map((user) => {
            if (user.id === action.payload) {
              user.setIsFollowed = false;
            }
            return user;
          });
        }
      })
      .addCase(unfollowUser.rejected, (state, action) => {
        if (state.currentUser && state.currentUser?.id === action.payload) {
          state.currentUser.setIsFollowed = false;
          state.isFollowed = false;
        } else {
          state.suggestedUsers = state.suggestedUsers.map((user) => {
            if (user.id === action.payload) {
              user.setIsFollowed = false;
            }
            return user;
          });
        }
      })
      .addCase(bookmarkNews.fulfilled, (state, action) => {
        const news = state.userNews.find((n) => n.id === action.payload);
        if (news) {
          news.setBookmark = true;
        }
      })
      .addCase(unBookmarkNews.fulfilled, (state, action) => {
        const news = state.userNews.find((n) => n.id === action.payload);
        if (news) {
          news.setBookmark = false;
        }
      })
      // .addCase(blockUser.fulfilled, (state) => {
      //   // Handle block user success
      // })
      // .addCase(deleteNews.fulfilled, (state) => {
      //   // Handle delete news success
      // })
      .addCase(getUserNews.fulfilled, (state, action) => {
        if (state.currentPage === 1) {
          state.userNews = [];
          state.isCurrentLastPage = false;
        }
        state.userNews.push(...action.payload.news);
        state.isCurrentLastPage = action.payload.isLastPage;
      })
      .addCase(refreshProfileNews.fulfilled, (state, action) => {
        state.userNews = [];
        state.userNews = action.payload.news;
        state.isCurrentLastPage = action.payload.isLastPage;
      })
      // .addCase(createReport.fulfilled, (state) => {
      //   // Handle create report success
      // })
      // .addCase(professionalDeny.fulfilled, (state) => {
      //   state.isProBannerDismissed = true;
      // });
      .addCase(suggestedUsersToFollow.fulfilled, (state, action) => {
        state.suggestedUsers = action.payload;
      });
  },
});

export const { resetProfile, incrementPage, setIsCurrentLastPage, updateProfileImage, updateProfileData } =
  profileSlice.actions;

export default profileSlice.reducer;
