import { inject, injectable } from "inversify";
import { ResultFuture } from "../../../../core/types/types";
import { NewsModel } from "../../../../product/models/news/newsModel";
import type { IProfileRepository } from "../repositories/IProfileRepository";
import { ProfileContainerTypes } from "../../../../product/di_containers/containerTypes/profileContainerTypes";

@injectable()
export class GetNewsByUserId {
  @inject(ProfileContainerTypes.IProfileRepository)
  private profileRepository!: IProfileRepository;

  async execute(params: GetNewsByUserIdParams): ResultFuture<NewsModel[]> {
    return this.profileRepository.getNewsByUserId(params.userId, params.page);
  }
}

export class GetNewsByUserIdParams {
  public userId: number;
  public page: number;

  constructor({ userId, page }: { userId: number; page: number }) {
    this.userId = userId;
    this.page = page;
  }
}
