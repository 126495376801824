import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthStatus } from "../../stores/auth/AuthStatus";
import { useSelector } from "react-redux";
import { RootState } from "../../stores";
import { useAppDispatch } from "../../stores/hooks";
import { checkIsAuthenticated } from "../../stores/auth/authSlice";

const ProtectedRouteV2: React.FC = () => {
  const { status, error, loading } = useSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    if (status === AuthStatus.Idle) {
      dispatch(checkIsAuthenticated());
    }
  }, [status, dispatch]);

  // Allow access to /news-details/:param without authentication
  if (
    location.pathname.startsWith("/news-details/") ||
    location.pathname.startsWith("/login") ||
    location.pathname.startsWith("/register")
  ) {
    return <Outlet />;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <Navigate to='/landing' replace />;
  }

  if (status === AuthStatus.Authenticated) {
    return <Outlet />;
  }
  if (status === AuthStatus.NotAuthenticated) {
    return <Navigate to='/landing' replace />;
  }
  return <div>Loading...</div>;
};

export default ProtectedRouteV2;
