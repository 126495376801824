import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import "./createNews.css";
import Header from "../../../components/header/Header";
import MobileMenu from "../../../components/mobileMenu/MobileMenu";
import { useAppDispatch } from "../../../stores/hooks";
import { publishNews } from "../../../stores/general/actions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";


import FadeLoader from "react-spinners/ClipLoader";
import base64ToFile from "./components/base64ToFile";
import { CreateNewsParams } from "../../../features/news/domain/useCases/params/CreateNewsParams";

import ChoseCategory from "./NewsPopups/ChoseCategory";
import LocationPopupContent from "./NewsPopups/LocationPopupContent";
import SourcePopupContent from "./NewsPopups/SourcePopupContent";
import SensitiveContentPopup from "./NewsPopups/SensitiveContent";
import OtherPopupContent from "./NewsPopups/OtherPopup";


const CreateNewsV2: React.FC = () => {
  const categoriesIco: string =
    require("../../../product/assets/icons/categoriesico.svg").default;
  const locationIco: string =
    require("../../../product/assets/icons/locationIco.svg").default;
  const searchIco: string =
    require("../../../product/assets/icons/searchIco.svg").default;
  const rightIco: string =
    require("../../../product/assets/icons/right.svg").default;
  const userIco: string =
    require("../../../product/assets/icons/user.svg").default;
  const rightIcoBlack: string =
    require("../../../product/assets/icons/right-black.svg").default;
  const noImage: string = require("../../../product/assets/loaders/no-img.avif");

  const eyeClosed: string =
    require("../../../product/assets/icons/eye-closed.svg").default;
  const dot3: string =
    require("../../../product/assets/icons/dot3.svg").default;

    


  const userProfileData = useSelector(
    (state: RootState) => state.ReducerGeneral.userProfileData,
  );
  const loadingPublishNews = useSelector(
    (state: RootState) => state.ReducerGeneral.loadingPublishNews,
  );
  const successPublishNews = useSelector(
    (state: RootState) => state.ReducerGeneral.successPublishNews,
  );
  const errorPublishNews = useSelector(
    (state: RootState) => state.ReducerGeneral.errorPublishNews,
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    successPublishNews && navigate("/");
  }, [successPublishNews]);

  const quillRef = useRef<ReactQuill>(null);

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const imageUrl = reader.result as string;

          const quill = quillRef.current?.getEditor();
          if (quill) {
            const range = quill.getSelection();
            if (range && range.index !== null) {
              quill.insertEmbed(range.index, "image", imageUrl);
              quill.setSelection({
                index: range.index + 1,
                length: 0,
              });
            }
          }
        };
        reader.readAsDataURL(file);
      }
    };
  }, []);

  const imageArray: string[] = [];

  const [previewPage, setPreviewPage] = useState(false);
  const handleContinue = () => {
    setPreviewPage(true);
  };

  const imageArrayPreview: string[] = [];
  const [imagePreview, setImagePreview] = useState("");
  let currentTextPreview = "";
  const [textPreviewer, setTextPreviewer] = useState("");
  const [hideBtnPreview, setHideBtnPreview] = useState(false);

  const handlePreviewPost = () => {
    setHideBtnPreview(true);

    const quill = quillRef.current?.getEditor();

    if (quill) {
      const delta = quill.getContents();
      delta.ops &&
        delta.ops.forEach((op: any) => {
          if (op.insert) {
            if (typeof op.insert === "object" && "image" in op.insert) {
              if (op.insert.image) {
                imageArrayPreview.push(op.insert.image);
                console.log("image prev" + imageArrayPreview.length);
                setImagePreview(imageArrayPreview[0]);
              }
            }
          }
        });
    }

    setPreviewPage(true);
  };

  const selectedCat: string =
    require("../../../product/assets/icons/selected.svg").default;

  const editorModules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
      ],
      handlers: {
        image: imageHandler,
      },
    },
  };


  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  
  const toggleCategory = (index: number) => {
    setSelectedCategories((prevSelectedCategories) =>
      prevSelectedCategories.includes(index)
        ? prevSelectedCategories.filter((id) => id !== index)
        : [...prevSelectedCategories, index]
    );
  };
  

  const [inputTitle, setInputTitle] = useState("");

  const [inputSource, setInputSource] = useState("");

  const handleFinish = () => {
    const quill = quillRef.current?.getEditor();
    if (quill) {
      const delta = quill.getContents();
      const textArray: string[] = [];

      let currentText = "";

      delta.ops &&
        delta.ops.forEach((op: any) => {
          if (op.insert) {
            if (typeof op.insert === "string") {
              currentText += op.insert;
            } else if (typeof op.insert === "object" && "image" in op.insert) {
              if (currentText.trim()) {
                textArray.push(currentText.trim());
                currentText = "";
              }

              if (op.insert.image) {
                imageArray.push(op.insert.image);
              }
            }
          }
        });

      if (currentText.trim()) {
        textArray.push(currentText.trim());
      }

      while (textArray.length < imageArray.length) {
        textArray.push("");
      }

      while (imageArray.length < textArray.length) {
        imageArray.push("");
      }

      const imageFiles: File[] = imageArray
        .map((base64Image, index) => {
          return base64ToFile(base64Image, `image_${index}.png`);
        })
        .filter((file): file is File => file !== null); // Filter out null values

      const createNewsParams = new CreateNewsParams({
        title: inputTitle,
        descriptions: textArray,
        categories: selectedCategories,
        commentEnabled: true,
        watermarkEnabled: false,
        files: imageFiles,
        locationId: null,
        // locationId: selectedLocationId,
        referenceUrl: inputSource,
      });

      
      dispatch(publishNews(createNewsParams));
    }
  };




  const [newPubishable, setNewPubishable] = useState(false);

  const [showPopup, setShowPopup] = useState<boolean>(true);

  type PopupType = 'categories' | 'location' | 'source' | 'sensitiveContent' | 'other' | null;

const [activePopup, setActivePopup] = useState<PopupType>(null);

const togglePopup = (popupType: PopupType) => {
  setShowPopup((prev) => !prev);
  setActivePopup(popupType);
};

  const handleClickOutside = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (
      !target.closest(".select-cats") &&
      !target.closest(".review-post-right-txt")
    ) {
      setShowPopup(false);
    }
  };

  useEffect(() => {

    if (showPopup) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => document.removeEventListener("click", handleClickOutside);
  }, [showPopup]);


  //location
  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(
    null,
  );
  const handleLocationClick = (id: number) => {
    setSelectedLocationId(id);
  };
   //sensitive content
   const [selectedSensitiveContentId, setSelectedSensitiveContentId] = useState<number | null>(
    null,
  );
  const handleSensitiveContentClick = (id: number) => {
    setSelectedSensitiveContentId(id);
  };
  const closePopups = ()=>{
    setShowPopup(false);
    setActivePopup(null)
  }


 
  return (
    <>
      {
       showPopup && activePopup === 'categories' ?   <ChoseCategory closePopups={closePopups} selectedCategories={selectedCategories} setSelectedCategories={setSelectedCategories} toggleCategory={toggleCategory} />
       : showPopup && activePopup === 'location' ?   <LocationPopupContent  selectedLocationId={selectedLocationId}
        handleLocationClick={handleLocationClick} closePopups={closePopups}   />
    
       : showPopup && activePopup === 'source' ?  <SourcePopupContent  closePopups={closePopups} setInputSource={setInputSource} inputSource={inputSource} />
    
       : showPopup && activePopup === 'sensitiveContent' ? <SensitiveContentPopup handleSensitiveContentClick={handleSensitiveContentClick}  selectedSensitiveContentId={selectedSensitiveContentId } closePopups={closePopups}/>
      :
       showPopup && activePopup === 'other' ? <OtherPopupContent closePopups={closePopups} />
      : 
        ""
      }

      <section className="max-widthed">
        <Header noSearchBox={true} />

        <section className="home-content feeds-section">
          <div
            className="publish-left-review"
            style={{ display: previewPage ? "block" : "none" }}
          >
            <div className="review-post">
              <div className="review-post-left">
                <div className="review-post-box">
                  <img
                    className="prev-img-post"
                    src={imagePreview ? imagePreview : noImage}
                  />

                  <div className="my-user-inf">
                    <div className="post-head-left-intro-my">
                      <img
                        src={userIco}
                        alt="avatar"
                        className="poster-img-intro-my"
                      />

                      <div className="single-post-user-intro-my">
                        <span className="name-surname-intro-my"></span>
                        <span className="usr-name-intro-my">
                          @ {userProfileData && userProfileData.nickname}
                        </span>
                      </div>
                    </div>
                  </div>

                  <p className="desc-review-post">{inputTitle}</p>
                </div>
              </div>

              <div className="review-post-right">
                <div className="review-post-row">
                  <div className="review-post-left-img">
                    <img src={categoriesIco} />
                  </div>

                  <div
                    className="review-post-right-txt"
                    onClick={() => togglePopup("categories")}
                  >
                    <h4> Kategori ekle</h4>
                    <span className="info-review-post">
                      
                      En fazla 3 adet kategori ekleyebilirsin.
                    </span>
                    <br />
                  </div>
                </div>

                <div className="review-post-row">
                  <div className="review-post-left-img">
                    <img src={locationIco} />
                  </div>

                  <div
                    className="review-post-right-txt"
                    onClick={() => togglePopup("location")}
                  >
                    <h4> Konum ekle</h4>
                    <span className="info-review-post"> Gönderi konumu</span>

                    {/*   <select className='regis-select' value={selectedLocationId} onChange={handleLocationChange}>
                      {locationList.map((location) => (
                        <option key={location.id} value={location.id}>
                          {location.name}
                        </option>
                      ))}
                    </select> 
                    */}
                  </div>
                </div>

                <div className="review-post-row">
                  <div className="review-post-left-img">
                    <img src={searchIco} />
                  </div>

                  <div
                    className="review-post-right-txt"
                    onClick={() => togglePopup("source")}
                  >
                    <h4> Kaynak ekle</h4>

                    <span className="info-review-post">
                      
                      Kaynakça ekleyerek gönderini daha güvenli hale
                      getirebilirsin.
                    </span> 
                  </div>
                </div>

                <div className="review-post-row">
                  <div className="review-post-left-img">
                    <img src={eyeClosed} />
                  </div>

                  <div
                    className="review-post-right-txt"
                    onClick={() => togglePopup("sensitiveContent")}
                  >
                    <h4> Hassas içerik </h4>
                    <span className="info-review-post">
                      
                      Gönderine hassas içerik uyarısı ekleyerek topluluğumuzun
                      daha güvenli şekilde gezinmesine yardımcı olabilirsin.
                    </span>
                  </div>
                </div>

                <div className="review-post-row">
                  <div className="review-post-left-img">
                    <img src={dot3} />
                  </div>

                  <div
                    className="review-post-right-txt"
                    onClick={() => togglePopup("other")}
                  >
                    <h4> Diğer </h4>
                    <span className="info-review-post">
                      
                      Diğer seçenekler ile gönderini özelleştir
                    </span>
                  </div>
                </div>

                <div className="add-cats buttons-23">

             

                
                  <button onClick={()=> navigate("/") }> İptal et </button>

                  {loadingPublishNews && newPubishable ? (


             <button  className="loader-btn">   <FadeLoader   color="#21914E"   size={23}/>   </button>

      ) : !loadingPublishNews && newPubishable ? (
        // selectedLocationId == "0" ?  <span className="publish-btn top-right-abs
        // low-opacity"   onClick={()=>console.log("enter location")}>  Haberi paylaş
        // </span> :   <FadeLoader color="white" /> 

      <button onClick={handleFinish}> Jurnalle </button>


      ) : (
        ""
      )}


                 
                </div>
              </div>
            </div>
          </div>

          <div
            className="publish-left"
            style={{ display: previewPage ? "none" : "block" }}
          >
            <div className="title-pubish">
              <textarea
                cols={40}
                rows={2}
                value={inputTitle}
                onChange={(e) => setInputTitle(e.target.value)}
                style={{ width: "84%", overflowWrap: "break-word" }}
                placeholder="Title"
              />
            </div>
            <ReactQuill
              ref={quillRef}
              theme="snow"
              modules={editorModules}
              placeholder="Haber içeriği..."
            />
          </div>
        </section>
     
      </section>

      {inputTitle.length ? (
        <span
          className={
            hideBtnPreview
              ? "publish-btn top-right-abs hide-btn green-next"
              : "publish-btn top-right-abs green-next"
          }
          onClick={() => {
            handlePreviewPost();
            setNewPubishable(true);
          }}
        >
          Ileri <img src={rightIcoBlack} />
        </span>
      ) : (
        <span
          className={
            hideBtnPreview
              ? "publish-btn top-right-abs hide-btn"
              : "publish-btn top-right-abs"
          }
          onClick={() => {
            console.log("fill the title");
          }}
        >
          Ileri <img src={rightIco} />
        </span>
      )}

 
    </>
  );
};

export default CreateNewsV2;
