import React from "react";
import { UserModel } from "../../../../product/models/user/userModel";
import "./SuggestedUsersListTile.css";
import BaseOutlinedButton from "../../../../components/buttons/outlinedButton/BaseOutlinedButton";
import BaseTextButton from "../../../../components/buttons/textButton/BaseTextButton";

interface SuggestUsersListTileProps {
  user: UserModel;
  onFollowToggled: (userId: number | undefined, isFollowing: boolean) => void;
  isFollowed: boolean;
}

const SuggestedUsersListTile: React.FC<SuggestUsersListTileProps> = ({ user, onFollowToggled, isFollowed }) => {
  const user_profile: string = require("../../../../product/assets/icons/user_profile.svg").default;
  const verifiedBadge: string = require("../../../../product/assets/icons/verified-badge.svg").default;

  return (
    <div className='suggest-user-block'>
      <img src={user_profile} alt='avatar' className='profile-usr' />
      <div className='single-post-user-profile-usr'>
        <span className='name-surname-profile-usr'>{user.fullName}</span>
        <span className='usr-name-profile-usr'>@{user.nickname}</span>
      </div>
      {user.verified && (
        <div className='verified-badge'>
          <img src={verifiedBadge} alt='badge' />
        </div>
      )}
      <div className='suggested-users-list-tile-button'>
        {isFollowed ? (
          <BaseOutlinedButton onClick={() => onFollowToggled(user.id, isFollowed)}>Takibi bırak</BaseOutlinedButton>
        ) : (
          <BaseTextButton onClick={() => onFollowToggled(user.id, isFollowed)} isPrimary={true}>
            Takip Et
          </BaseTextButton>
        )}
      </div>
    </div>
  );
};

export default SuggestedUsersListTile;
