import { inject, injectable } from "inversify";
import { ProfileContainerTypes } from "../../../../product/di_containers/containerTypes/profileContainerTypes";
import type { IProfileRepository } from "../repositories/IProfileRepository";
import { ResultFuture } from "../../../../core/types/types";

@injectable()
export class FollowUser {
  @inject(ProfileContainerTypes.IProfileRepository)
  private profileRepository!: IProfileRepository;

  async execute(userId: number | undefined): ResultFuture<void> {
    return this.profileRepository.followUser(userId);
  }
}
