import React from "react";
import "./NewsDetailsShimmer.css";

const NewsDetailsShimmer: React.FC = () => (
  <div className='news-details-shimmer'>
    <div className='shimmer-ad'></div>
    <div className='shimmer-title'></div>
    <div className='shimmer-location'></div>
    <div className='shimmer-date'></div>
    <div className='shimmer-buttons-row'>
      <div className='shimmer-button-item'></div>
      <div className='shimmer-button-item'></div>
      <div className='shimmer-button-item'></div>
      <div className='shimmer-button-item'></div>
    </div>
    <div className='shimmer-user'>
      <div className='shimmer-user-avatar'></div>
      <div className='shimmer-user-info'>
        <div className='shimmer-user-name'></div>
        <div className='shimmer-user-nickname'></div>
      </div>
      <div className='shimmer-follow-button'></div>
    </div>
    <div className='shimmer-content-block-description'></div>
    <div className='shimmer-content-block'></div>
    <div className='shimmer-content-block-description'></div>
    <div className='shimmer-content-block'></div>
    <div className='shimmer-categories'></div>
    <div className='shimmer-action-buttons'></div>
  </div>
);

export default NewsDetailsShimmer;
