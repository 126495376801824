import { CategoryRemoteDataSource } from "./data/dataSources/CategoryRemoteDataSource";
import { ICategoryRemoteDataSource } from "./data/dataSources/ICategoryRemoteDataSource";
import { ContainerModule, interfaces } from "inversify";
import { CategoryRepository } from "./data/repositories/CategoryRepository";
import { ICategoryRepository } from "./domain/repositories/ICategoryRepository";
import { GetUserCategories } from "./domain/useCases/getCategories";
import { CategoryTypes } from "./categoryTypes";
import { ICategoryLocalDataSource } from "./data/dataSources/ICategoryLocalDataSource";
import { CategoryLocalDataSource } from "./data/dataSources/CategoryLocalDataSource";
import { GetAllCategories } from "./domain/useCases/GetAllCategories";

const categoryContainer = new ContainerModule((bind: interfaces.Bind) => {
  bind<ICategoryRemoteDataSource>(CategoryTypes.ICategoryRemoteDataSource).to(CategoryRemoteDataSource);
  bind<ICategoryLocalDataSource>(CategoryTypes.ICategoryLocalDataSource).to(CategoryLocalDataSource);
  bind<ICategoryRepository>(CategoryTypes.ICategoryRepository).to(CategoryRepository);
  bind<GetUserCategories>(CategoryTypes.GetUserCategories).to(GetUserCategories);
  bind<GetAllCategories>(CategoryTypes.GetAllCategories).to(GetAllCategories);
});

export { categoryContainer };
