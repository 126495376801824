const categories: string[] = [
  "Teknoloji",
  "Kültür&sanat",
  "Eğitim",
  "Finans",
  "Spor",
  "Ekonomi",
  "Müzik bilim",
  "Sağlık",
  "Gündem",
  "Seyehat",
  "Eğlence",
  "Sinema",
  "Yapay zeka",
  "Kariyer",
  "Mizah",
  "Moda",
  "Viral",
  "Siyaset",
  "Tasarım",
  "Category",
];

export default categories;
