import React, { useState } from "react";
import { NewsModel } from "../../../../../product/models/news/newsModel";
import "./FeedNewsCard.css";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as CommentIcon } from "../../../../../product/assets/iconsV2/outlined/comment.svg";
import { ReactComponent as VerificationIcon } from "../../../../../product/assets/iconsV2/filled/verify.svg";
import { ReactComponent as DenyIcon } from "../../../../../product/assets/iconsV2/filled/deny.svg";
import { ReactComponent as Share } from "../../../../../product/assets/iconsV2/outlined/share_arrow.svg";
import { ReactComponent as Bookmark } from "../../../../../product/assets/iconsV2/outlined/bookmark.svg";
import { ReactComponent as MoreOptions } from "../../../../../product/assets/iconsV2/filled/three_dots_horizontal.svg";
import { ReactComponent as EyeIcon } from "../../../../../product/assets/iconsV2/outlined/eye_open.svg";
import UserAvatar from "../../../../../product/components/user/UserAvatar";
import DateTimeUtil from "../../../../../product/utils/dateTime/DateTimeUtil";

const FeedNewsCard: React.FC<{ news: NewsModel }> = ({ news }) => {
  const user = news.user;
  const [thumbnailError, setThumbnailError] = useState(false);
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/news-details/${news.slug}`);
  };

  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <div className='news-card-column'>
      <div className='news-card' onClick={handleCardClick}>
        <div className='news-content-left'>
          <div className='news-head'>
            <div className='news-head-left'>
              <UserAvatar userId={user?.id} imageUrl={user?.imageUrl} />
              <div className='news-user'>
                <span className='news-name-surname'>
                  {user?.fullName}{" "}
                  <span className='time-info'>{DateTimeUtil.parseDateTimeToHourMinute(news?.createdAt)}</span>
                </span>
              </div>
            </div>
          </div>
          <div className='news-content-row'>
            <div className='news-card-content'>
              <Link className='news-card-title' to={`/news-details/${news.slug}`} onClick={stopPropagation}>
                {news.getTitle}
                {news.getDescription && (
                  <p className='news-card-description'>{news.getDescription.substring(0, 200)}...</p>
                )}
              </Link>
            </div>
            {news.thumbnail && !thumbnailError && (
              <img
                src={news.thumbnail}
                alt='thumbnail'
                className='news-thumbnail'
                onError={() => setThumbnailError(true)}
              />
            )}
          </div>
          <div className='news-card-footer'>
            <div className='news-footer-left'>
              <span className='feed-ico-group' onClick={stopPropagation}>
                <VerificationIcon />
                <div className='news-card-counts'>{news.getVerificationCount}</div>
              </span>
              <span className='feed-ico-group' onClick={stopPropagation}>
                <DenyIcon />
                <div className='news-card-counts'>{news.getDenialCount}</div>
              </span>
              <span className='feed-ico-group' onClick={stopPropagation}>
                <CommentIcon />
                <div className='news-card-counts'>{news.getCommentCount}</div>
              </span>
              <span className='feed-ico-group' onClick={stopPropagation}>
                <EyeIcon />
                <div className='news-card-counts'>{news.getViewCount}</div>
              </span>
            </div>
            <div className='news-footer-right'>
              <span className='feed-ico-group' onClick={stopPropagation}>
                <Share />
              </span>
              <span className='feed-ico-group' onClick={stopPropagation}>
                <Bookmark />
              </span>
              <span className='feed-ico-group' onClick={stopPropagation}>
                <MoreOptions />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedNewsCard;
