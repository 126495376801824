import { CreateNewsParams } from "../../domain/useCases/params/CreateNewsParams";

export interface CreateNewsDTO {
  title: string;
  descriptions: string[];
  referenceUrl: string | null;
  commentEnabled: boolean;
  watermarkEnabled: boolean;
  categories: number[];
  locationId: number | null;
}

export function toCreateNewsDTO(params: CreateNewsParams): CreateNewsDTO {
  const { title, descriptions, referenceUrl, commentEnabled, watermarkEnabled, categories, locationId } = params;
  return {
    title,
    descriptions,
    referenceUrl,
    commentEnabled,
    watermarkEnabled,
    categories,
    locationId,
  };
}
