import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import feedReducer from "./news/feed/feedSlice";
import newsDetailReducer from "./news/newsDetails/newsDetailsSlice";
import notificationReducer from "./notification/notificationSlice";
import profileReducer from "./profile/ProfileSlice"
import ReducerApp from "./news/reducer";
import ReducerGeneral from "./general/reducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    feed: feedReducer,
    newsDetails: newsDetailReducer,
    notification: notificationReducer,
    profile : profileReducer,
    ReducerApp: ReducerApp,
    ReducerGeneral: ReducerGeneral,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
