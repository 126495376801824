
import React, { useState } from "react";
import CopyrightsText from "./CopyrightText";
import SensitiveContentList from "../components/sensitiveContentList";

interface SensitiveContentProps {
  selectedSensitiveContentId: number | null;
  handleSensitiveContentClick: (id: number) => void;
  closePopups: () => void;
}


const SensitiveContentPopup: React.FC<SensitiveContentProps> = ({ selectedSensitiveContentId, handleSensitiveContentClick, closePopups }) => {

  const cancelIco: string =
    require("../../../../product/assets/icons/cance.svg").default;

  const nonSelectedPoint: string =
    require("../../../../product/assets/icons/non-seleccted-point.svg").default;
  const selectedPoint: string =
    require("../../../../product/assets/icons/selected-point.svg").default;


  return (
    <div className="choose-categories">
      <div className="select-cats">
        <div className="categories-slct">
          <div className="pop-heading">
            <div className="left-poped">
              <h4>Hasas Içerik</h4>
              <span> Gönderine hassas içerik uyarısı ekleyerek topluluğumuzun daha güvenli şekilde gezinmesine yardımcı olabilirsin.</span>
            </div>

            <div className="right-poped">
              <img src={cancelIco} alt="ico " onClick={closePopups} />
            </div>
          </div>

          <div className="select-sensitives">
            {SensitiveContentList.map((sensitiveItem) => (
              <div
                key={sensitiveItem.id}
                className={`cities-list sensitive-item ${selectedSensitiveContentId === sensitiveItem.id ? "selected" : ""
                  }`}
                onClick={() => handleSensitiveContentClick(sensitiveItem.id)}
              >
                <div className="cities-cnt">
                  <div className="flex-cnt">

                    {sensitiveItem.name}
                  </div>

                  <div>

                    <img
                      src={
                        selectedSensitiveContentId === sensitiveItem.id
                          ? selectedPoint
                          : nonSelectedPoint
                      }
                      alt="icon"
                    />
                  </div>


                </div>
                <p className="sensitive-desc">  {sensitiveItem.description}</p>



              </div>
            ))}

          </div>
          <CopyrightsText />
          <div className="add-cats">
            <button onClick={closePopups} >Ekle</button>
          </div>
        </div>
      </div>
    </div>

  )
}

export default SensitiveContentPopup