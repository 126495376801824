import React from "react";
import "./NewsDetailsAd.css";

const NewsDetailsAd: React.FC = () => {
  const handleAdClick = () => {
    window.location.href =
      "https://www.shopier.com/ShowProductNew/storefront.php?shop=zeatelierr&sid=STJ3MFB4RkxUYWhaazd3ZTBfLTFfIF8g";
  };

  return (
    <div className='news-details-ad' onClick={handleAdClick}>
      <img src='/assets/ze-atelier.png' alt='Ze Atelier' />
    </div>
  );
};
export default NewsDetailsAd;
