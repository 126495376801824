import { inject, injectable } from "inversify";
import type { INewsRepository } from "../repositories/iNewsRepository";
import { ResultFuture } from "../../../../core/types/types";
import { NewsContainerTypes } from "../../newsContainerTypes";
import { NewsModel } from "../../../../product/models/news/newsModel";
import { CreateNewsParams } from "./params/CreateNewsParams";

@injectable()
export class CreateNews {
  @inject(NewsContainerTypes.INewsRepository) private newsRepo!: INewsRepository;

  async execute(params: CreateNewsParams): ResultFuture<NewsModel> {
    return this.newsRepo.createNews(params);
  }
}
