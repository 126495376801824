import { createAsyncThunk } from "@reduxjs/toolkit";
import { container } from "../../product/di_containers/inversify.config";
import { ProfileContainerTypes } from "../../product/di_containers/containerTypes/profileContainerTypes";
import { FollowUser } from "../../features/profile/domain/useCases/FollowUser";
import { UnfollowUser } from "../../features/profile/domain/useCases/UnfollowUser";
import { BookmarkNews } from "../../features/news/domain/useCases/BookmarkNews";
import { NewsContainerTypes } from "../../features/news/newsContainerTypes";
import { UnBookmarkNews } from "../../features/news/domain/useCases/UnBookmarkNews";
import { GetProfileParams, GetUserProfile } from "../../features/profile/domain/useCases/GetUserProfile";
import { GetNewsByUserId } from "../../features/profile/domain/useCases/GetNewsByUserId";
import { SuggestedUsersToFollow } from "../../features/profile/domain/useCases/SuggestedUsersToFollow";
import { UserModel } from "../../product/models/user/userModel";

export const followUser = createAsyncThunk<
  number | undefined,
  number | undefined,
  {
    rejectValue: string;
  }
>("profile/followUser", async (userId, thunkAPI) => {
  const followUserUseCase = container.get<FollowUser>(ProfileContainerTypes.FollowUser);
  const result = await followUserUseCase.execute(userId);

  return result.fold(
    (error) => thunkAPI.rejectWithValue(error.message),
    () => userId
  );
});

export const unfollowUser = createAsyncThunk<
  number | undefined,
  number | undefined,
  {
    rejectValue: string;
  }
>("profile/unfollowUser", async (userId, thunkAPI) => {
  const unfollowUserUseCase = container.get<UnfollowUser>(ProfileContainerTypes.UnfollowUser);
  const result = await unfollowUserUseCase.execute(userId);

  return result.fold(
    (error) => thunkAPI.rejectWithValue(error.message),
    () => userId
  );
});

export const suggestedUsersToFollow = createAsyncThunk<any, void, { rejectValue: string }>(
  "profile/followUsersSuggestion",
  async (_, thunkAPI) => {
    const followUsersSuggestionUseCase = container.get<SuggestedUsersToFollow>(
      ProfileContainerTypes.SuggestedUsersToFollow
    );
    const result = await followUsersSuggestionUseCase.execute();

    return result.fold(
      (error) => thunkAPI.rejectWithValue(error.message),
      (users) => users
    );
  }
);

export const bookmarkNews = createAsyncThunk<void, number, { rejectValue: string }>(
  "profile/bookmarkNews",
  async (newsId, thunkAPI) => {
    const bookmarkNewsUseCase = container.get<BookmarkNews>(NewsContainerTypes.BookmarkNews);
    const result = await bookmarkNewsUseCase.execute(newsId);

    return result.fold(
      (error) => thunkAPI.rejectWithValue(error.message),
      () => {}
    );
  }
);

export const unBookmarkNews = createAsyncThunk<void, number, { rejectValue: string }>(
  "profile/unBookmarkNews",
  async (newsId, thunkAPI) => {
    const unBookmarkNewsUseCase = container.get<UnBookmarkNews>(NewsContainerTypes.UnBookmarkNews);
    const result = await unBookmarkNewsUseCase.execute(newsId);

    return result.fold(
      (error) => thunkAPI.rejectWithValue(error.message),
      () => {}
    );
  }
);

export const getUserById = createAsyncThunk<
  UserModel,
  { profileParams: GetProfileParams },
  {
    rejectValue: string;
  }
>("profile/getUserById", async (params, thunkAPI) => {
  const getUserByIdUseCase = container.get<GetUserProfile>(ProfileContainerTypes.GetUserProfile);
  const result = await getUserByIdUseCase.execute(params.profileParams);
  return result.fold(
    (error) => thunkAPI.rejectWithValue(error.message),
    (user) => user
  );
});

export const getUserNews = createAsyncThunk<
  { news: any[]; isLastPage: boolean },
  {
    userId: number;
    page: number;
  },
  { rejectValue: string }
>("profile/getUserNews", async (params, thunkAPI) => {
  const getUserNewsUseCase = container.get<GetNewsByUserId>(ProfileContainerTypes.GetNewsByUserId);
  const result = await getUserNewsUseCase.execute(params);

  return result.fold(
    (error) => thunkAPI.rejectWithValue(error.message),
    (news) => ({
      news,
      isLastPage: news.length < 10, // Assuming 10 is the page size
    })
  );
});

export const refreshProfileNews = createAsyncThunk<
  { news: any[]; isLastPage: boolean },
  number,
  {
    rejectValue: string;
  }
>("profile/refreshProfileNews", async (userId, thunkAPI) => {
  const getUserNewsUseCase = container.get<GetNewsByUserId>(ProfileContainerTypes.GetNewsByUserId);
  const result = await getUserNewsUseCase.execute({ userId, page: 1 });

  return result.fold(
    (error) => thunkAPI.rejectWithValue(error.message),
    (news) => ({
      news,
      isLastPage: news.length < 10, // Assuming 10 is the page size
    })
  );
});

// export const blockUser = createAsyncThunk<void, number, { rejectValue: string }>(
//   "profile/blockUser",
//   async (userId, thunkAPI) => {
//     const blockUserUseCase = container.get<BlockUser>(ProfileContainerTypes.BlockUser);
//     const result = await blockUserUseCase.execute(userId);
//
//     return result.fold(
//       (error) => thunkAPI.rejectWithValue(error.message),
//       () => {}
//     );
//   }
// );

// export const deleteNews = createAsyncThunk<void, number, { rejectValue: string }>(
//   "profile/deleteNews",
//   async (newsId, thunkAPI) => {
//     const deleteNewsUseCase = container.get<DeleteNews>(ProfileContainerTypes.DeleteNews);
//     const result = await deleteNewsUseCase.execute(newsId);
//
//     return result.fold(
//       (error) => thunkAPI.rejectWithValue(error.message),
//       () => {}
//     );
//   }
// );

// export const createReport = createAsyncThunk<void, any, { rejectValue: string }>(
//   "profile/createReport",
//   async (params, thunkAPI) => {
//     const createReportUseCase = container.get<CreateReport>(ProfileContainerTypes.CreateReport);
//     const result = await createReportUseCase.execute(params);
//
//     return result.fold(
//       (error) => thunkAPI.rejectWithValue(error.message),
//       () => {}
//     );
//   }
// );

// export const professionalDeny = createAsyncThunk<void, string, { rejectValue: string }>(
//   "profile/professionalDeny",
//   async (dateTime, thunkAPI) => {
//     const setProBannerDismissedUseCase = container.get<SetProBannerDismissed>(
//       ProfileContainerTypes.SetProBannerDismissed
//     );
//     const result = await setProBannerDismissedUseCase.execute(dateTime);
//
//     return result.fold(
//       (error) => thunkAPI.rejectWithValue(error.message),
//       () => {}
//     );
//   }
// );