import { createAsyncThunk } from "@reduxjs/toolkit";
import { NewsModel } from "../../../product/models/news/newsModel";
import { container } from "../../../product/di_containers/inversify.config";
import { GetNewsDetails } from "../../../features/news/domain/useCases/GetNewsDetails";
import { NewsContainerTypes } from "../../../features/news/newsContainerTypes";
import { Failure } from "../../../core/error/failure";
import { VerifyNews } from "../../../features/news/domain/useCases/VerifyNews";
import { DenyNews } from "../../../features/news/domain/useCases/DenyNews";
import { BookmarkNews } from "../../../features/news/domain/useCases/BookmarkNews";
import { UnBookmarkNews } from "../../../features/news/domain/useCases/UnBookmarkNews";
import { ShareNews } from "../../../features/news/domain/useCases/ShareNews";
import { RootState } from "../../index";

export const fetchNewsDetails = createAsyncThunk<
  NewsModel,
  string | undefined,
  {
    rejectValue: string;
  }
>("news/fetchNewsDetails", async (params: string | undefined, thunkAPI) => {
  const getNewsDetailsUseCase = container.get<GetNewsDetails>(NewsContainerTypes.GetNewsDetails);
  const newsDetails = await getNewsDetailsUseCase.execute(params);
  return newsDetails.fold(
    (error: Failure) => thunkAPI.rejectWithValue(error.message),
    (news: NewsModel) => thunkAPI.fulfillWithValue(news)
  );
});

export const verifyNews = createAsyncThunk<
  boolean,
  number | undefined,
  {
    rejectValue: string;
  }
>("news/verifyNews", async (params: number | undefined, thunkAPI) => {
  const verifyNewsUseCase = container.get<VerifyNews>(NewsContainerTypes.VerifyNews);
  const newsDetails = await verifyNewsUseCase.execute(params);
  return newsDetails.fold(
    (error: Failure) => thunkAPI.rejectWithValue(error.message),
    (isVerified: boolean) => {
      return thunkAPI.fulfillWithValue(isVerified);
    }
  );
});

export const denyNews = createAsyncThunk<
  boolean,
  number | undefined,
  {
    rejectValue: string;
  }
>("news/denyNews", async (params: number | undefined, thunkAPI) => {
  const denyNewsUseCase = container.get<DenyNews>(NewsContainerTypes.DenyNews);
  const newsDetails = await denyNewsUseCase.execute(params);
  return newsDetails.fold(
    (error: Failure) => thunkAPI.rejectWithValue(error.message),
    (isDenied: boolean) => {
      return thunkAPI.fulfillWithValue(isDenied);
    }
  );
});

export const toggleBookmark = createAsyncThunk<
  boolean,
  number | undefined,
  {
    rejectValue: string;
  }
>("news/toggleBookmark", async (params: number | undefined, thunkAPI) => {
  const newsDetailsState = (thunkAPI.getState() as RootState).newsDetails;

  if (newsDetailsState.isBookmarked) {
    const unBookmarkNewsUseCase = container.get<UnBookmarkNews>(NewsContainerTypes.UnBookmarkNews);
    const unBookmarked = await unBookmarkNewsUseCase.execute(params);
    return unBookmarked.fold(
      (error: Failure) => thunkAPI.rejectWithValue(error.message),
      () => {
        return thunkAPI.fulfillWithValue(false);
      }
    );
  } else {
    const bookmarkNewsUseCase = container.get<BookmarkNews>(NewsContainerTypes.BookmarkNews);
    const bookmarked = await bookmarkNewsUseCase.execute(params);
    return bookmarked.fold(
      (error: Failure) => thunkAPI.rejectWithValue(error.message),
      () => {
        return thunkAPI.fulfillWithValue(true);
      }
    );
  }
});

export const shareNews = createAsyncThunk<
  boolean,
  string,
  {
    rejectValue: string;
  }
>("news/shareNews", async (params: string, thunkAPI) => {
  const shareNewsUseCase = container.get<ShareNews>(NewsContainerTypes.ShareNews);
  const shared = await shareNewsUseCase.execute(params);
  return shared.fold(
    (error: Failure) => thunkAPI.rejectWithValue(error.message),
    (isShared: boolean) => {
      return thunkAPI.fulfillWithValue(isShared);
    }
  );
});
