import { inject, injectable } from "inversify";
import { ProfileContainerTypes } from "../../../../product/di_containers/containerTypes/profileContainerTypes";
import type { IProfileRepository } from "../repositories/IProfileRepository";
import { ResultFuture } from "../../../../core/types/types";
import { UserModel } from "../../../../product/models/user/userModel";

@injectable()
export class SuggestedUsersToFollow {
  @inject(ProfileContainerTypes.IProfileRepository)
  private profileRepository!: IProfileRepository;

  async execute(): ResultFuture<UserModel[]> {
    return this.profileRepository.suggestedUsersToFollow();
  }
}
