import { ContainerModule, interfaces } from "inversify";
import { IAuthLocalDataSource } from "../../../features/auth/data/data_sources/auth.local.data-source.interface";
import { IAuthRemoteDataSource } from "../../../features/auth/data/data_sources/auth.remote.data-source.interface";
import { IAuthRepository } from "../../../features/auth/domain/repositories/auth.repository.interface";
import { SignIn } from "../../../features/auth/domain/useCases/sign-in.usecase";
import { CheckIsAuthenticatedUseCase } from "../../../features/auth/domain/useCases/CheckIsAuthenticatedUseCase";
import { AuthLocalDataSource } from "../../../features/auth/data/data_sources/auth.local.data-source";
import { AuthRemoteDataSource } from "../../../features/auth/data/data_sources/auth.remote.data-source";
import { AuthRepository } from "../../../features/auth/data/repositories/auth.repository";
import { AuthContainerTypes } from "../containerTypes/autContainerTypes";
import { RemoveAccountUseCase } from "../../../features/auth/domain/useCases/removeAccountUseCase";

const authContainer = new ContainerModule((bind: interfaces.Bind) => {
  bind<IAuthRemoteDataSource>(AuthContainerTypes.IAuthRemoteDataSource).to(AuthRemoteDataSource);
  bind<IAuthLocalDataSource>(AuthContainerTypes.IAuthLocalDataSource).to(AuthLocalDataSource);
  bind<IAuthRepository>(AuthContainerTypes.IAuthRepository).to(AuthRepository);
  bind<SignIn>(AuthContainerTypes.SignIn).to(SignIn);
  bind<CheckIsAuthenticatedUseCase>(AuthContainerTypes.CheckIsAuthenticatedUseCase).to(CheckIsAuthenticatedUseCase);
  bind<RemoveAccountUseCase>(AuthContainerTypes.RemoveAccountUseCase).to(RemoveAccountUseCase);
});

export { authContainer };
