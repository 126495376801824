import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks";
import "./suggestedUsers.css";
import { followUser, suggestedUsersToFollow, unfollowUser } from "../../../../stores/profile/ProfileThunks";
import SuggestedUsersListTile from "./SuggestedUsersListTile";
import { UserModel } from "../../../../product/models/user/userModel";

const SuggestedUsersList: React.FC = () => {
  const userAppDispatch = useAppDispatch();

  const { suggestedUsers } = useAppSelector((state) => state.profile);

  useEffect(() => {
    userAppDispatch(suggestedUsersToFollow());
  }, []);

  const handleFollowUser = (userId: number | undefined, isFollowing: boolean) => {
    isFollowing ? userAppDispatch(unfollowUser(userId)) : userAppDispatch(followUser(userId));
  };

  return (
    <div className='suggest-container'>
      <div className='right-block-suggest'>
        <h3>Senin için öneriler</h3>
        {suggestedUsers.map((user: UserModel) => (
          <SuggestedUsersListTile
            key={user.id}
            user={user}
            onFollowToggled={handleFollowUser}
            isFollowed={user.isFollowed}
          />
        ))}
      </div>
    </div>
  );
};

export default SuggestedUsersList;
