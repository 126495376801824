import { ApiException } from "next-netkit";

export class Failure extends Error {
  public statusCode: number;

  constructor(message: string, statusCode: number = 500) {
    super(message);
    this.name = "Failure";
    this.statusCode = statusCode;
  }
}

export class ApiFailure extends Failure {
  public statusCode: number;
  public messages: string[];

  constructor({ statusCode, messages = [], message }: { statusCode?: number; messages?: string[]; message?: string }) {
    super(messages[0] || message || "An API error occurred");
    this.statusCode = statusCode || 500;
    this.messages = messages;
  }

  static fromException(apiException: ApiException): ApiFailure {
    return new ApiFailure({
      statusCode: apiException.statusCode,
      messages: apiException.messages,
      message: apiException.message,
    });
  }
}
