
import React, { useState } from "react";
import categories from "../components/AllCategoriesList";
interface ChoseCategoryProps {
  closePopups: () => void;
  setSelectedCategories: React.Dispatch<React.SetStateAction<number[]>>;
  toggleCategory: (index: number) => void;
  selectedCategories: Number[]
}

const ChoseCategory: React.FC<ChoseCategoryProps> = ({ closePopups, toggleCategory, selectedCategories }) => {

  const cancelIco: string =
    require("../../../../product/assets/icons/cance.svg").default;

  const selectedCat: string =
    require("../../../../product/assets/icons/selected.svg").default;
  const searchIco: string =
    require("../../../../product/assets/icons/search.svg").default;

  const [searchQuery, setSearchQuery] = useState<string>("");

  const filteredCategories = categories.filter((category) =>
    category.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="choose-categories">
      <div className="select-cats">
        <div className="categories-slct">
          <div className="pop-heading">
            <div className="left-poped">
              <h4>Kategori seç</h4>
              <span> En fazla üç kategori ekleyebilirsin.</span>
            </div>

            <div className="right-poped">
              <img src={cancelIco} alt="ico" onClick={closePopups} />
            </div>
          </div>

          <div className="input-search-cat">
            <img src={searchIco} alt="icon" />
            <input className="search-cat" value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)} 
            />

          </div>

          {filteredCategories.map((category, index) => (
            <span
              key={index}
              className={
                selectedCategories.includes(index)
                  ? "single-cat selected-cat"
                  : "single-cat"
              }
              onClick={() => {
                if (selectedCategories.length < 3 || selectedCategories.includes(index)) {
                  toggleCategory(index);
                }
              }}

            >
              {category} <img src={selectedCat} alt="" />
            </span>
          ))}

        </div>

        <div className="add-cats">
          <button onClick={closePopups} >Ekle</button>
        </div>
      </div>
    </div>

  )
}

export default ChoseCategory