
const CopyrightsText = () => {

  const informationIco: string =
  require("../../../../product/assets/icons/information.svg").default;

  return (
    <div className="copy-right-txt">
      <img src={informationIco} /> <span>Topluluk kuralları </span> ve{" "}
      <span> Telif haklarıyla </span> ilgili daha fazla bilgi al.
    </div>
  );
};

export default CopyrightsText