import React from "react";
import "../../../components/header/header.css";
import { useNavigate } from "react-router-dom";

import "./CreateNewsHeader.css";

interface HeaderProps {
  noSearchBox?: boolean;
}

const CreateNewsHeader: React.FC<HeaderProps> = (props) => {
  const navigate = useNavigate();
  const Notifications_ico: string = require("../../assets/icons/Notifications.svg").default;
  const avatar_profile_ico: string = require("../../assets/icons/avatar_profile.svg").default;
  const jurnalleLogo: string = require("../../assets/jurnalle.svg").default;

  const handleCreateNewsClick = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    if (event.ctrlKey || event.metaKey) {
      window.open("/", "_blank");
    } else {
      navigate("/");
    }
  };
  return (
    <header className='header-v2'>
      <img src={jurnalleLogo} alt='logo' className='create-news-header-logo' onClick={handleCreateNewsClick} />
      <div className='create-news-header-bar'>
        {!props.noSearchBox && (
          <div className='nav-right-boxes-v2'>
            <div className='nav-right-box'>
              <img src={Notifications_ico} alt='notification' />
            </div>
            <div className='nav-right-box'>
              <img src={avatar_profile_ico} alt='user' />
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default CreateNewsHeader;
