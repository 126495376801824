import "reflect-metadata";
import { Container } from "inversify";
import { networkContainer } from "./network.container";
import { newsContainer } from "../../features/news/newsContainer";
import { categoryContainer } from "../../features/_common/category/categoryContainer";
import { notificationContainer } from "../../features/notification/notificationContainer";
import { profileContainer } from "./containers/profileContainer";
import { authContainer } from "./containers/authContainer";

// Create the main container
const container = new Container();

// Merge module containers
container.load(authContainer);
container.load(networkContainer);
container.load(newsContainer);
container.load(categoryContainer);
container.load(notificationContainer);
container.load(profileContainer);

export { container };
