export class APIConst {
  static readonly BASE_URL = "https://api-prod.jurnalle.com";
  // static readonly BASE_URL = "http://localhost:8080";
  static readonly DEV_BASE_URL = "https://api-dev.jurnalle.com";
  static readonly JURNALLE_ABOUT = "https://jurnalle.com/";
  static readonly JURNALLE_FAQ = "https://jurnalle.com/sss";
  static readonly JURNALLE_PRIVACY_POLICY = "https://jurnalle.com/privacy_policy";
  static readonly JURNALLE_TERMS_OF_SERVICE = "https://jurnalle.com/terms_of_service";
  static readonly JURNALLE_SUPPORT_EMAIL = "support@jurnalle._app";
  static readonly JURNALLE_WHATSAPP = "https://wa.me/905060640842";
  static readonly APPLY_PROFESSIONAL_ACCOUNT = "https://studio.jurnalle.com/pro/submission/account-type";
  static readonly JURNALLE_INSTAGRAM = "https://www.instagram.com/jurnalleapp/";
  static readonly JURNALLE_TWITTER = "https://www.twitter.com/jurnalleapp";
  static readonly JURNALLE_APP_STORE = "https://apps.apple.com/app/jurnalle/id6466410768";
  static readonly JURNALLE_GOOGLE_PLAY = "https://play.google.com/store/apps/details?id=com.jurnalle.jurnalle";
  static readonly API = "api/v1";
  static readonly APP_STATUS_INIT = `${APIConst.API}/app_status/init/v2`;

  // Auth
  static readonly AUTH = `${APIConst.API}/web/auth`;
  static readonly REFRESH_TOKEN = `api/v1/web/auth/refresh-token`;
  static readonly CHECK_TOKEN = `${APIConst.AUTH}/check-token`;
  static readonly SIGN_IN = `${APIConst.AUTH}/sign-in`;
  static readonly SIGN_UP = `${APIConst.AUTH}/sign-up`;
  static readonly REMOVE_ACCOUNT = `${APIConst.AUTH}/remove-account-by-credentials`;

  // Email Verification
  static readonly EMAIL_VERIFICATION = `${APIConst.API}/email-verification`;
  static readonly VERIFY_EMAIL = `${APIConst.EMAIL_VERIFICATION}/request`;

  // News
  static readonly NEWS = "api/news";
  static readonly CREATE_NEWS = `${APIConst.NEWS}/v2`;
  static readonly NEWS_VALIDATION = `${APIConst.API}/news-validation`;
  static readonly COMMENT = `${APIConst.API}/comment`;
  static readonly LOCATIONS = `${APIConst.API}/locations`;
  // Report
  static readonly REPORT = `${APIConst.API}/report`;
  // User
  static readonly USER = `${APIConst.API}/user`;
  static readonly USER_BLOCK = `${APIConst.API}/user-block`;
  // Profile
  static readonly PROFILE_V2 = `${APIConst.USER}/profile/v2`;
  static readonly PROFILE = `${APIConst.USER}/profile`;
  static readonly UPDATE_BIO = `${APIConst.PROFILE}/bio`;
  static readonly USER_IMAGE = `${APIConst.PROFILE}/image`;
  static readonly FOLLOW = `${APIConst.API}/follow`;
  static readonly SUGGESTED_USERS_TO_FOLLOW = `${APIConst.FOLLOW}/suggested-users`;
  static readonly USER_CATEGORIES = `${APIConst.API}/user-category`;
  static readonly CATEGORY = `${APIConst.API}/category`;
  static readonly ELIGIBILITY_CRITERIA = `${APIConst.API}/professional-account/eligibility`;
  static readonly PROFESSIONAL_FEATURES = `${APIConst.API}/professional-account/features`;
  // Notification
  static readonly NOTIFICATION = `${APIConst.API}/notification`;
  static readonly UNREAD_NOTIFICATIONS_COUNT = `${APIConst.NOTIFICATION}/unread-count`;
  static readonly ALL_READ_NOTIFICATIONS = `${APIConst.NOTIFICATION}/read-all`;
  static readonly ALL_NOTIFICATIONS = `${APIConst.NOTIFICATION}/all`;
  static readonly DISABLED_NOTIFICATIONS = `${APIConst.NOTIFICATION}/disabled-preferences`;

  static verifyEmailCode(code: string): string {
    return `${APIConst.EMAIL_VERIFICATION}/verify?verificationCode=${code}`;
  }

  static verifyComments(commentId: number): string {
    return `${APIConst.API}/comment-validation/verify?commentId=${commentId}`;
  }

  static denyComments(commentId: number): string {
    return `${APIConst.API}/comment-validation/deny?commentId=${commentId}`;
  }

  static commentByNewsId(newsId: number, page: number): string {
    return `${APIConst.COMMENT}/${newsId}?page=${page}`;
  }

  static repliedComments(parentCommentId: number, page: number): string {
    return `${APIConst.COMMENT}/replied/${parentCommentId}?page=${page}`;
  }

  static commentById(commentId: number): string {
    return `${APIConst.COMMENT}/${commentId}`;
  }

  static feedNews(page: number, categoryId?: number): string {
    return categoryId === 0 || categoryId == null
      ? `${APIConst.NEWS}?page=${page}`
      : `${APIConst.NEWS}?categoryId=${categoryId}&page=${page}`;
  }

  static newsById(newsId: number): string {
    return `${APIConst.NEWS}/${newsId}`;
  }

  static newsBySlug(newsSlug: string): string {
    return `${APIConst.NEWS}/details/${newsSlug}`;
  }

  static shareNews(newsSlug: string): string {
    return `${APIConst.NEWS}/share/${newsSlug}`;
  }

  static verifyNews(newsId: number): string {
    return `${APIConst.NEWS_VALIDATION}/verify?newsId=${newsId}`;
  }

  static verifiedNewsUsers(newsId: number, page: number): string {
    return `${APIConst.NEWS_VALIDATION}/verified-users/${newsId}?page=${page}`;
  }

  static denyNews(newsId: number): string {
    return `${APIConst.NEWS_VALIDATION}/deny?newsId=${newsId}`;
  }

  static denyNewsUsers(newsId: number, page: number): string {
    return `${APIConst.NEWS_VALIDATION}/denied-users/${newsId}?page=${page}`;
  }

  static verifiedNews(page: number): string {
    return `${APIConst.NEWS}/verified?page=${page}`;
  }

  static deniedNews(page: number): string {
    return `${APIConst.NEWS}/denied?page=${page}`;
  }

  static searchLocations(location: string, page: number): string {
    return `${APIConst.LOCATIONS}/search?keyword=${location}&page=${page}`;
  }

  static newsByLocation(locationId: number, page: number): string {
    return `${APIConst.NEWS}/location?locationId=${locationId}&page=${page}`;
  }

  static bookmarks(page: number): string {
    return `${APIConst.API}/bookmark?page=${page}`;
  }

  static newsByUserId({ userId, page }: { userId: number; page: number }): string {
    return `${APIConst.NEWS}/user/${userId}?page=${page}`;
  }

  static bookmarkNews(newsId: number): string {
    return `${APIConst.API}/bookmark/${newsId}`;
  }

  static userBlock(userId: number): string {
    return `${APIConst.USER_BLOCK}/${userId}`;
  }

  static blockedUsers(page: number): string {
    return `${APIConst.USER_BLOCK}/blocked?page=${page}`;
  }

  static searchUsers(key: string): string {
    return `${APIConst.USER}/search?keyword=${key}`;
  }

  static getProfile(userId: number): string {
    return `${APIConst.PROFILE}/${userId}`;
  }

  static followUnfollow(userId: number): string {
    return `${APIConst.FOLLOW}/${userId}`;
  }

  static followers({ userId, page }: { userId: number; page: number }): string {
    return `${APIConst.FOLLOW}/followers/${userId}?page=${page}`;
  }

  static followings({ userId, page }: { userId: number; page: number }): string {
    return `${APIConst.FOLLOW}/followings/${userId}?page=${page}`;
  }

  static notifications(page: number): string {
    return `${APIConst.NOTIFICATION}?page=${page}`;
  }

  static updateNotificationPreference(id: number): string {
    return `${APIConst.NOTIFICATION}/preference/${id}`;
  }

  static removeNotification(id: number): string {
    return `${APIConst.NOTIFICATION}/${id}`;
  }

  static markNotificationAsRead(id: number): string {
    return `${APIConst.NOTIFICATION}/read/${id}`;
  }
}
