export {};

class UrlHelper {
  private readonly url: string;

  constructor(url: string = window.location.pathname) {
    this.url = url; 
  }

  public getIdFromCurrentUrl(): number | undefined {
    const regex = /\/other-profile\/(\d+)/;
    const match = this.url.match(regex);
    return match ? parseInt(match[1], 10) : undefined;
  }
}

export default UrlHelper; 
