import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthState } from "./AuthState";
import { AuthStatus } from "./AuthStatus";
import { container } from "../../product/di_containers/inversify.config";
import { SignIn } from "../../features/auth/domain/useCases/sign-in.usecase";
import { SignInDto } from "../../features/auth/data/dto/sign-in.dto";
import { Failure } from "../../core/error/failure";
import { ResultVoid } from "../../core/types/types";
import { RemoveAccountUseCase } from "../../features/auth/domain/useCases/removeAccountUseCase";
import { CheckIsAuthenticatedUseCase } from "../../features/auth/domain/useCases/CheckIsAuthenticatedUseCase";
import { AuthContainerTypes } from "../../product/di_containers/containerTypes/autContainerTypes";

const initialState: AuthState = {
  user: null,
  status: AuthStatus.Idle,
  error: null,
  isProfileComplete: false,
  layoutLoading: true,
  loading: false,
};

export const checkIsAuthenticated = createAsyncThunk<void, void, { rejectValue: string }>(
  "auth/check-is-authenticated",
  async (_, thunkAPI) => {
    const checkIsAuthenticatedUseCase = container.get<CheckIsAuthenticatedUseCase>(
      AuthContainerTypes.CheckIsAuthenticatedUseCase
    );
    const result: ResultVoid = await checkIsAuthenticatedUseCase.execute();

    return result.fold(
      (error: Failure) => thunkAPI.rejectWithValue(error.message),
      () => thunkAPI.fulfillWithValue(undefined)
    );
  }
);

export const removeUserAccount = createAsyncThunk<void, SignInDto, { rejectValue: string }>(
  "auth/remove-account",
  async (removeRequest: SignInDto, thunkAPI) => {
    const removeAccountUseCase = container.get<RemoveAccountUseCase>(AuthContainerTypes.RemoveAccountUseCase);

    const result: ResultVoid = await removeAccountUseCase.execute(removeRequest);
    return result.fold(
      (error: Failure) => thunkAPI.rejectWithValue(error.message),
      () => thunkAPI.fulfillWithValue(undefined)
    );
  }
);

export const signInUser = createAsyncThunk<void, SignInDto, { rejectValue: string }>(
  "auth/signInReducer",
  async (dto: SignInDto, thunkAPI) => {
    const signInUseCase = container.get<SignIn>(AuthContainerTypes.SignIn);

    const result: ResultVoid = await signInUseCase.execute(dto);

    return result.fold(
      (error: Failure) => thunkAPI.rejectWithValue(error.message), // Reject with ApiFailure if
      // sign-in fails
      () => thunkAPI.fulfillWithValue(undefined) // Fulfill with void if sign-in succeeds
    );
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    checkIsAuthenticatedReducer(builder);

    // Sign-in cases
    signInReducer(builder);

    // Account removal cases
    accountRemovedReducer(builder);
  },
});

function checkIsAuthenticatedReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder
    .addCase(checkIsAuthenticated.pending, (state) => {
      state.layoutLoading = true;
      state.error = null;
    })
    .addCase(checkIsAuthenticated.fulfilled, (state) => {
      state.layoutLoading = false;
      state.status = AuthStatus.Authenticated;
    })
    .addCase(checkIsAuthenticated.rejected, (state, action) => {
      state.layoutLoading = false;
      state.status = AuthStatus.NotAuthenticated;
    });
}

function signInReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder
    .addCase(signInUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(signInUser.fulfilled, (state, action) => {
      state.loading = false;
      state.status = AuthStatus.Authenticated;
    })
    .addCase(signInUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || "Sign-in failed. Please try again.";
    });
}

function accountRemovedReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder
    .addCase(removeUserAccount.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(removeUserAccount.fulfilled, (state) => {
      state.status = AuthStatus.UserRemoved;
      state.loading = false;
      state.user = null;
      state.isProfileComplete = false;
    })
    .addCase(removeUserAccount.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || "Failed to remove account.";
    });
}

export default authSlice.reducer;
