import React from "react";
import { Outlet } from "react-router-dom";
import MobileMenu from "../../mobileMenu/MobileMenu";
import "./CreateNewsLayout.css";
import CreateNewsHeader from "../../../../product/components/header/CreateNewsHeader";

const CreateNewsLayout: React.FC = () => {
  return (
    <div className='create-news-container'>
      <div className='create-news-main-content create-news-layout'>
        <div className='create-news-layout-content'>
          <CreateNewsHeader />
          <Outlet />
        </div>
      </div>
      <MobileMenu />
    </div>
  );
};

export default CreateNewsLayout;
