import { inject, injectable } from "inversify";
import { ApiFailure } from "../../../../core/error/failure";
import { ResultFuture } from "../../../../core/types/types";
import { Left, Right } from "../../../../core/types/either";
import { ApiException } from "next-netkit";
import { INewsRepository } from "../../domain/repositories/iNewsRepository";
import type { INewsRemoteDataSource } from "../dataSources/iNewsRemoteDataSource";
import { NewsModel } from "../../../../product/models/news/newsModel";
import { PaginationParams } from "../../../../product/params/paginationParams";
import { GetFeedNewsParams } from "../../domain/useCases/getFeedNews";
import { NewsContainerTypes } from "../../newsContainerTypes";
import { CreateNewsDTO, toCreateNewsDTO } from "../dto/CreateNewsDTO";
import { CreateNewsParams } from "../../domain/useCases/params/CreateNewsParams";
import { NewsEntity } from "../dto/newsEntity";
import { plainToClass, plainToInstance } from "class-transformer";

@injectable()
export class NewsRepository implements INewsRepository {
  @inject(NewsContainerTypes.INewsRemoteDataSource) private remoteDataSource!: INewsRemoteDataSource;

  async getDeniedNews(params: PaginationParams): ResultFuture<NewsModel[]> {
    try {
      const newsEntities = await this.remoteDataSource.getDeniedNews(params);
      const newsModels: NewsModel[] = plainToInstance(NewsModel, newsEntities);
      return new Right(newsModels);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async getVerifiedNews(params: PaginationParams): ResultFuture<NewsModel[]> {
    try {
      const newsEntities = await this.remoteDataSource.getVerifiedNews(params);
      const newsModels: NewsModel[] = plainToInstance(NewsModel, newsEntities);
      return new Right(newsModels);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async getFeedNews(params: GetFeedNewsParams): ResultFuture<NewsModel[]> {
    try {
      const newsEntities = await this.remoteDataSource.getFeedNews(params);
      const newsModels: NewsModel[] = plainToInstance(NewsModel, newsEntities);
      return new Right(newsModels);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async getNewsDetails(slug: string | undefined): ResultFuture<NewsModel> {
    try {
      if (slug === undefined) {
        return new Left(new ApiFailure({ message: "Invalid news slug" }));
      }
      const result: NewsEntity = await this.remoteDataSource.getNewsDetails(slug);
      const newsModel = plainToClass(NewsModel, result);

      return new Right(newsModel);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async verifyNews(id: number | undefined): ResultFuture<boolean> {
    try {
      if (id === undefined) {
        return new Left(new ApiFailure({ message: "Invalid news id" }));
      }
      await this.remoteDataSource.verifyNews(id);
      return new Right(true);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async denyNews(id: number | undefined): ResultFuture<boolean> {
    try {
      if (id === undefined) {
        return new Left(new ApiFailure({ message: "Invalid news id" }));
      }
      await this.remoteDataSource.denyNews(id);
      return new Right(true);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async bookmarkNews(id: number | undefined): ResultFuture<void> {
    try {
      if (id === undefined) {
        return new Left(new ApiFailure({ message: "Invalid news id" }));
      }
      await this.remoteDataSource.bookmarkNews(id);
      return new Right(undefined);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async unBookmarkNews(id: number | undefined): ResultFuture<void> {
    try {
      if (id === undefined) {
        return new Left(new ApiFailure({ message: "Invalid news id" }));
      }
      await this.remoteDataSource.unBookmarkNews(id);
      return new Right(undefined);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async shareNews(slug: string | undefined): ResultFuture<boolean> {
    try {
      if (slug === undefined) {
        return new Left(new ApiFailure({ message: "Invalid news slug" }));
      }
      await this.remoteDataSource.shareNews(slug);
      return new Right(true);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async createNews(params: CreateNewsParams): ResultFuture<NewsModel> {
    try {
      const formData = new FormData();

      // Append files to FormData
      params.files.forEach((file: File, _: any) => {
        formData.append(`files`, file);
      });

      // Convert CreateNewsParams to CreateNewsDTO
      const createNewsDTO: CreateNewsDTO = toCreateNewsDTO(params);

      // Append content as JSON
      const jsonBlob = new Blob([JSON.stringify(createNewsDTO)], { type: "application/json" });
      formData.append("news", jsonBlob);

      // Log FormData contents for debugging
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }

      const result = await this.remoteDataSource.createNews(formData);
      return new Right(result);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }
}
