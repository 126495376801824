import { inject, injectable } from "inversify";
import "reflect-metadata";
import { SignInDto } from "../../data/dto/sign-in.dto";
import type { IAuthRepository } from "../repositories/auth.repository.interface";
import { ResultFutureVoid } from "../../../../core/types/types";
import { AuthContainerTypes } from "../../../../product/di_containers/containerTypes/autContainerTypes";

@injectable()
export class SignIn {
  @inject(AuthContainerTypes.IAuthRepository)
  private authRepo!: IAuthRepository;

  async execute(dto: SignInDto): ResultFutureVoid {
    return this.authRepo.signIn(dto);
  }
}
