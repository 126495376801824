import React, { CSSProperties, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FadeLoader from "react-spinners/ClipLoader";

import { useAppDispatch } from "../../../stores/hooks";

import "./login.css";

//redux
import { RootState } from "../../../stores";
import { useSelector } from "react-redux";
import { checkIsAuthenticated, signInUser } from "../../../stores/auth/authSlice";
import { AuthStatus } from "../../../stores/auth/AuthStatus";

const Login: React.FC = () => {
  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const logoGreen: string = require("../../../product/assets/jurnalle-green.svg").default;

  const [showRegisterEmail, setShowRegisterEmail] = useState(true);

  const { status, error, loading } = useSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(true);

  const handleLogin = () => {
    dispatch(signInUser({ email, password, deviceType: "web" }));
  };

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(checkIsAuthenticated());

    if (status === AuthStatus.Authenticated) {
      navigate("/feeds");
    }
  }, [navigate, status]);

  return (
    <>
      <div className='login-content'>
        <div className='regis-parts'>
          <img src={logoGreen} alt='' className='logo-login' />
          <div className='regis-box'>
            <span className='box-title'>Login</span>

            <div className='input-box'>
              <input
                type='text'
                placeholder='E-posta'
                value={email}
                className={error ? "input-typer" : ""}
                onChange={(e) => setEmail(e.target.value)}
              />
              {error ? (
                <span className='wrong-data-alert'>E-postan yanlış veya hatalı</span>
              ) : (
                <span className='info-data-alert'></span>
              )}
            </div>

            <div className='input-box'>
              <input
                className={error ? "input-typer" : ""}
                type='password'
                placeholder='Şifre'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {error ? (
                <span className='wrong-data-alert'>Şifren yanlış veya hatalı</span>
              ) : (
                <span className='info-data-alert'></span>
              )}
            </div>

            <span
              className='regis-btns'
              onClick={
                handleLogin
                //   handleSignup
                // dispatch(signup({email , nameSurname, userName, password }))
                //showregisterNameSurnameScreen
              }
            >
              {loading ? <FadeLoader color='white' /> : " Giriş yap"}
            </span>

            <div className='have-account'>
              <span> Zaten hesabın var mı? </span>{" "}
              <Link to='/register' className='color-green'>
                Kaydol
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
