import { injectable } from "inversify";
import { IAuthLocalDataSource } from "./auth.local.data-source.interface";
import { UserDTO } from "../dto/user.dto";
import { UserEntity } from "../../../../product/models/user/userEntity";

@injectable()
export class AuthLocalDataSource implements IAuthLocalDataSource {
  saveUser(user: UserDTO): void {
    localStorage.setItem("user", JSON.stringify(user));
  }

  getUser(): UserEntity | null {
    const user = localStorage.getItem("user");
    return user ? JSON.parse(user) : null;
  }
}
