
import React, { useState } from "react";
import CopyrightsText from "./CopyrightText";
import { Switch } from "@mui/material";

interface OtherPopupContentProps {
  closePopups: () => void;
}


const OtherPopupContent: React.FC<OtherPopupContentProps> = ({ closePopups }) => {

  const cancelIco: string =
    require("../../../../product/assets/icons/cance.svg").default;

  const commentsIco: string =
    require("../../../../product/assets/icons/comment.svg").default;

  const copyrightsIco: string =
    require("../../../../product/assets/icons/copyrights.svg").default;

  const switchLabel = { inputProps: { 'aria-label': 'Switch demo' } };

  return (
    <div className="choose-categories">
      <div className="select-cats">
        <div className="categories-slct">
          <div className="pop-heading">
            <div className="left-poped">
              <h4>Diğer seçenekler</h4>
              <span> Gönderi üzerindeki eylemler ve orijinal içerik koruması (Telif hakları) gibi özellikleri belirle.</span>
            </div>

            <div className="right-poped">
              <img src={cancelIco} alt="ico " onClick={closePopups} />
            </div>
          </div>
          <div className="select-sensitives">

            <div className="cities-list sensitive-item">
              <div className="cities-cnt">
                <div className="flex-cnt"> <img src={commentsIco} alt="ico" /> <h4>Yorumları kısıtla </h4>  </div>
                <div>
                  <Switch {...switchLabel} />
                </div>
              </div>
              <p className="sensitive-desc"> Gönderine kimse tarafından yorum yapılamaz.</p>
            </div>

            <div className="cities-list sensitive-item">
              <div className="cities-cnt">
                <div className="flex-cnt">  <img src={copyrightsIco} alt="ico" />  Filigran ekle  </div>
                <div>
                  <Switch {...switchLabel} />
                </div>
              </div>
              <p className="sensitive-desc"> Eklediğin görsellerin izinsiz kullanılmaması için üzerine Jurnalle logosu eklenir.</p>
            </div>
          </div>

          <CopyrightsText />
          <div className="add-cats">
          <button onClick={closePopups} >Ekle</button>
          </div>
        </div>
      </div>
    </div>

  )
}

export default OtherPopupContent