import { CategoryModel } from "../category/categoryModel";
import { LocationModel } from "../location/locationModel";
import { UserModel } from "../user/userModel";
import { NewsValidationEnum, NewsValidationEnumExtension } from "../../enums/newsValidationEnum";
import { NewsContentBlockModel } from "./newsContentBlockModel";

export class NewsModel {
  public id: number | undefined;
  public slug: string | undefined;
  public user: UserModel | undefined;
  public thumbnail: string | undefined;
  public contentBlocks: NewsContentBlockModel[] | undefined;
  public referenceUrl: string | undefined;
  public commentEnabled: boolean | undefined;
  public editedAt: string | undefined;
  public createdAt: string | undefined;
  public updatedAt: string | undefined;
  public categories: CategoryModel[] | undefined;
  public location: LocationModel | undefined;
  private verificationCount: number | undefined;
  private denialCount: number | undefined;
  private referenceCount: number | undefined;
  private viewCount: number | undefined;
  private readCount: number | undefined;
  private archiveCount: number | undefined;
  private commentCount: number | undefined;
  private shareCount: number | undefined;
  private watermarkEnabled: boolean | undefined;
  private userValidation: string | undefined;
  private bookmarked: boolean | undefined;
  private isPrivate: boolean | undefined;
  private description: string | undefined;
  private title: string | undefined;

  get getTitle(): string {
    return (this.title ?? "").replace(/\n/g, "").replace(/[*_\-~`]/g, "");
  }

  get getDescription(): string {
    return (this.description ?? "").replace(/\n/g, "").replace(/[*_\-~`]/g, "");
  }

  public get isBookmarked(): boolean {
    return this.bookmarked ?? false;
  }

  public get isWatermarkEnabled(): boolean {
    return this.watermarkEnabled ?? false;
  }

  public get isVerified(): boolean {
    return this.userValidation === NewsValidationEnumExtension.value(NewsValidationEnum.Verified);
  }

  public get isDenied(): boolean {
    return this.userValidation === NewsValidationEnumExtension.value(NewsValidationEnum.Denied);
  }

  public get isCommentEnabled(): boolean {
    return this.commentEnabled ?? false;
  }

  public get getCommentCount(): number {
    return this.commentCount ?? 0;
  }

  public get getVerificationCount(): number {
    return this.verificationCount ?? 0;
  }

  public get getDenialCount(): number {
    return this.denialCount ?? 0;
  }

  public get getViewCount(): number {
    return this.viewCount ?? 0;
  }

  public set setBookmark(value: boolean) {
    this.bookmarked = value;
  }
}
