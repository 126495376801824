import { inject, injectable } from "inversify";
import { ProfileContainerTypes } from "../../../../product/di_containers/containerTypes/profileContainerTypes";
import type { IProfileRepository } from "../repositories/IProfileRepository";
import { ResultFuture } from "../../../../core/types/types";
import { FollowPaginationParams } from "../../../../product/params/FollowPaginationParams";
import { UserModel } from "../../../../product/models/user/userModel";

@injectable()
export class GetFollowersByUserId {
  @inject(ProfileContainerTypes.IProfileRepository)
  private profileRepository!: IProfileRepository;

  async execute(params: FollowPaginationParams): ResultFuture<UserModel[]> {
    return this.profileRepository.getFollowersByUserId(params.userId, params.page);
  }
}
