import { inject, injectable } from "inversify";
import { ProfileContainerTypes } from "../../../../product/di_containers/containerTypes/profileContainerTypes";
import type { IProfileRepository } from "../repositories/IProfileRepository";
import { ResultFuture } from "../../../../core/types/types";
import { PaginationParams } from "../../../../product/params/paginationParams";
import { NewsModel } from "../../../../product/models/news/newsModel";

@injectable()
export class GetBookmarkedNews {
  @inject(ProfileContainerTypes.IProfileRepository)
  private profileRepository!: IProfileRepository;

  async execute(params: PaginationParams): ResultFuture<NewsModel[]> {
    return this.profileRepository.getBookmarkedNews(params);
  }
}
