import { inject, injectable } from "inversify";
import { ResultFuture } from "../../../../../core/types/types";
import { CategoryModel } from "../../../../../product/models/category/categoryModel";
import type { ICategoryRepository } from "../repositories/ICategoryRepository";
import { CategoryTypes } from "../../categoryTypes";

@injectable()
export class GetUserCategories {
  @inject(CategoryTypes.ICategoryRepository)
  private categoryRepository!: ICategoryRepository;

  async execute(): ResultFuture<CategoryModel[]> {
    return this.categoryRepository.getUserCategories();
  }
}
