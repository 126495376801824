import { SuggestedUsersToFollow } from "../../../features/profile/domain/useCases/SuggestedUsersToFollow";

const ProfileContainerTypes = {
  IProfileRemoteDataSource: Symbol.for("IProfileRemoteDataSource"),
  IProfileRepository: Symbol.for("IProfileRepository"),
  GetUserProfile: Symbol.for("GetUserProfile"),
  GetNewsByUserId: Symbol.for("GetNewsByUserId"),
  FollowUser: Symbol.for("FollowUser"),
  UnfollowUser: Symbol.for("UnfollowUser"),
  GetBookmarkedNews: Symbol.for("GetBookmarkedNews"),
  GetFollowersByUserId: Symbol.for("GetFollowersByUserId"),
  GetFollowingsByUserId: Symbol.for("GetFollowingsByUserId"),
  RemoveUserImage: Symbol.for("RemoveUserImage"),
  SuggestedUsersToFollow: Symbol.for("SuggestedUsersToFollow"),
};

export { ProfileContainerTypes };
