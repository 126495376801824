
import React, { useState } from "react";
import CopyrightsText from "./CopyrightText";

interface SourcePopupContentProps {
  closePopups: () => void;
  inputSource: string;
  setInputSource: React.Dispatch<React.SetStateAction<string>>;
}


const SourcePopupContent: React.FC<SourcePopupContentProps> = ({ inputSource, setInputSource, closePopups }) => {
  const cancelIco: string =
    require("../../../../product/assets/icons/cance.svg").default;



  return (
    <div className="choose-categories source-content">
      <div className="select-cats">
        <div className="categories-slct">
          <div className="pop-heading">
            <div className="left-poped">
              <h4>Kaynak</h4>
              <span>Kaynakça ekleyerek gönderini daha güvenli hale getirebilirsin.</span>
            </div>

            <div className="right-poped">
              <img src={cancelIco} alt="ico " onClick={closePopups} />
            </div>
          </div>

          <h5>Kaynak metin/URL</h5>

          <textarea
            name=""
            id=""
            placeholder="Metin/URL"
            className="textarea-source"

            value={inputSource}
            onChange={(e) => setInputSource(e.target.value)}

          >

          </textarea>

          <CopyrightsText />
        </div>

        <div className="add-cats">
          <button onClick={closePopups} >Ekle</button>
        </div>
      </div>
    </div>
  )
}

export default SourcePopupContent