export class UserModel {
  public id?: number;
  public nickname?: string;
  public email?: string;
  public fullName?: string;
  public createdAt?: string;
  public gender?: string;
  public location?: string;
  public birthdate?: string;
  public verified?: boolean;
  public emailVerified?: boolean;
  public phoneVerified?: boolean;
  public imageUrl?: string;
  public slogan?: string;
  public website?: string;
  public education?: string;
  public bio?: string;
  public contact?: string;
  public proTag?: string;
  public followersCount?: number;
  public followingsCount?: number;
  private followed?: boolean;

  get isFollowed(): boolean {
    return this.followed ?? false;
  }

  set setIsFollowed(value: boolean) {
    this.followed = value;
  }

  get followingsCountValue(): number {
    return this.followingsCount ?? 0;
  }

  get followersCountValue(): number {
    return this.followersCount ?? 0;
  }

  increaseFollowersCount(): number {
    this.followersCount = this.followersCountValue + 1;
    return this.followersCount;
  }

  decreaseFollowersCount(): number {
    this.followersCount = this.followersCountValue - 1;
    return this.followersCount;
  }

  increaseFollowingsCount(): number {
    this.followingsCount = this.followingsCountValue + 1;
    return this.followingsCountValue;
  }

  decreaseFollowingsCount(): number {
    this.followingsCount = this.followingsCountValue - 1;
    return this.followingsCountValue;
  }
}
