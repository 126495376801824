import React, { FormEvent, useState } from "react";


import { Link } from "react-router-dom";
import './mobileMenu.css';

const MobileMenu: React.FC = () => {

  const homeIcon: string = require("../../../product/assets/icons/menu/home.svg").default;
  const searchIco: string = require("../../../product/assets/icons/menu/search.svg").default;
  const NotificationsIco: string = require("../../../product/assets/icons/menu/Notifications.svg").default;
  const userIco: string = require("../../../product/assets/icons/menu/user.svg").default;

  return (
    <div className="mobile-menu">
      <div className="menu-elems">
        <Link to="/"  style={{ pointerEvents: 'none' }} > <img src={homeIcon} alt="icon" /> </Link>
        <Link to="/"  style={{ pointerEvents: 'none' }} ><img src={searchIco} alt="icon" /> </Link>
        <Link to="/"  style={{ pointerEvents: 'none' }} ><img src={NotificationsIco} alt="icon" /> </Link>
        <Link to="/"  style={{ pointerEvents: 'none' }} ><img src={userIco} alt="icon" /> </Link>
      </div>

    </div>

  );
};

export default MobileMenu;