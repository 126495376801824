import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as CommentIcon } from "../../../../product/assets/iconsV2/outlined/comment.svg";
import { ReactComponent as VerificationIcon } from "../../../../product/assets/iconsV2/filled/verify.svg";
import { ReactComponent as DenyIcon } from "../../../../product/assets/iconsV2/filled/deny.svg";
import { ReactComponent as Share } from "../../../../product/assets/iconsV2/outlined/share_arrow.svg";
import { ReactComponent as Bookmark } from "../../../../product/assets/iconsV2/outlined/bookmark.svg";
import { ReactComponent as MoreOptions } from "../../../../product/assets/iconsV2/filled/three_dots_horizontal.svg";
import UserAvatar from "../../../../product/components/user/UserAvatar";

const ProfileNewsCard: React.FC<{ news: Record<string, any> }> = ({ news }) => {
  const user = news.user || {};

  const [thumbnailError, setThumbnailError] = useState(false);
  const navigate = useNavigate();

  const formatTimeFromISO = (isoString?: string): string => {
    if (!isoString) {
      return "";
    }
    return `${new Date(isoString).getUTCHours().toString().padStart(2, "0")}:${new Date(isoString)
      .getUTCMinutes()
      .toString()
      .padStart(2, "0")}`;
  };

  const handleCardClick = () => {
    navigate(`/news-details/${news.slug}`);
  };

  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <div className='news-card-column'>
      <div className='news-card' onClick={handleCardClick}>
        <div className='news-content-left'>
          <div className='news-head'>
            <div className='news-head-left'>
              <UserAvatar userId={user?.id} imageUrl={user?.imageUrl} />
              <div className='news-user'>
                <span className='news-name-surname'>
                  {user?.fullName} <span className='time-info'>{formatTimeFromISO(news?.createdAt)}</span>
                </span>
              </div>
            </div>
          </div>
          <div className='news-content-row'>
            <div className='news-card-content'>
              <Link className='news-card-title' to={`/news-details/${news.slug}`} onClick={stopPropagation}>
                {news.title}
              </Link>
              {news.description && <p className='news-card-description'>{news.description.substring(0, 200)}...</p>}
            </div>
            {news.thumbnail && !thumbnailError && (
              <img
                src={news.thumbnail}
                alt='thumbnail'
                className='news-thumbnail'
                onError={() => setThumbnailError(true)}
              />
            )}
          </div>
          <div className='news-card-footer'>
            <div className='news-footer-left'>
              <span className='feed-ico-group' onClick={stopPropagation}>
                <VerificationIcon />
                <div className='news-card-counts'>{news.verificationCount}</div>
              </span>
              <span className='feed-ico-group' onClick={stopPropagation}>
                <DenyIcon />
                <div className='news-card-counts'>{news.denialCount}</div>
              </span>
              <span className='feed-ico-group' onClick={stopPropagation}>
                <CommentIcon />
                <div className='news-card-counts'>{news.commentCount}</div>
              </span>
            </div>
            <div className='news-footer-right'>
              <span className='feed-ico-group' onClick={stopPropagation}>
                <Share />
              </span>
              <span className='feed-ico-group' onClick={stopPropagation}>
                <Bookmark />
              </span>
              <span className='feed-ico-group' onClick={stopPropagation}>
                <MoreOptions />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileNewsCard;
