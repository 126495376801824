import { inject, injectable } from "inversify";
import { IAuthRemoteDataSource } from "./auth.remote.data-source.interface";
import { SignInDto } from "../dto/sign-in.dto";
import { SignInResponseDto } from "../dto/sign-in.response.dto";
import type { INetworkManager } from "next-netkit";
import { RequestMethod } from "next-netkit";
import { APIConst } from "../../../../product/constants/apiConst";

@injectable()
export class AuthRemoteDataSource implements IAuthRemoteDataSource {
  @inject("INetworkManager")
  private networkManager!: INetworkManager;

  async signIn(dto: SignInDto): Promise<SignInResponseDto> {
    return this.networkManager.request<SignInResponseDto>({
      method: RequestMethod.POST,
      url: APIConst.SIGN_IN,
      data: dto,
    });
  }

  async removeAccount(credentials: SignInDto): Promise<void> {
    await this.networkManager.requestVoid({
      method: RequestMethod.POST,
      url: APIConst.REMOVE_ACCOUNT,
      data: credentials,
    });
  }

  async checkAuthenticated(): Promise<void> {
    await this.networkManager.requestVoid({
      method: RequestMethod.POST,
      url: APIConst.CHECK_TOKEN,
    });
  }
}
