import React, { useRef, useState } from "react";
import "./share_social.css";
import { ReactComponent as Share } from "../../product/assets/iconsV2/outlined/share_arrow.svg";

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";

interface ShareSocialProps {
  url: string;
  title: string;
}

const ShareSocial: React.FC<ShareSocialProps> = ({ url, title }) => {
  const [isHovered, setIsHovered] = useState(false);
  const hoveringRef = useRef(false); // Ref to track hover state

  const handleMouseEnter = () => {
    hoveringRef.current = true;
    setIsHovered(true); // Show menu immediately on hover
  };

  const handleMouseLeave = () => {
    hoveringRef.current = false;
    // Add delay before hiding
    setTimeout(() => {
      if (!hoveringRef.current) {
        setIsHovered(false); // Hide menu only if hover is not true
      }
    }, 1000); // 1-second delay for animation
  };

  return (
    <div className='social-share-container' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Share />
      <div className={`social-share ${isHovered ? "visible" : ""}`}>
        <TwitterShareButton url={url} title={title} className='social-share-button'>
          <XIcon round />
        </TwitterShareButton>
        <WhatsappShareButton url={url} title={title} separator=':: ' className='social-share-button'>
          <WhatsappIcon round />
        </WhatsappShareButton>
        <LinkedinShareButton url={url} className='social-share-button'>
          <LinkedinIcon round />
        </LinkedinShareButton>
        <FacebookShareButton url={url} className='social-share-button'>
          <FacebookIcon round />
        </FacebookShareButton>
        <TelegramShareButton url={url} title={title} className='social-share-button'>
          <TelegramIcon round />
        </TelegramShareButton>

        <RedditShareButton url={url} title={title} windowWidth={660} windowHeight={460} className='social-share-button'>
          <RedditIcon round />
        </RedditShareButton>
      </div>
    </div>
  );
};

export default ShareSocial;
