// Convert base64 to File
const base64ToFile = (base64: string, filename: string): File | null => {
  const metadata = base64.split(",")[0];
  const base64Data = base64.split(",")[1];

  if (!metadata || !base64Data) {
    console.error("Invalid base64 string.");
    return null;
  }

  const mimeMatch = metadata.match(/:(.*?);/);
  if (!mimeMatch) {
    console.error("Could not extract MIME type.");
    return null;
  }

  const mimeString = mimeMatch[1];

  try {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeString });

    return new File([blob], filename, { type: mimeString });
  } catch (error) {
    console.error("Error processing base64 string:", error);
    return null;
  }
};

export default base64ToFile;
