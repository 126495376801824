import React from "react";
import BaseOutlinedButton from "../../../../components/buttons/outlinedButton/BaseOutlinedButton";
import BaseElevatedButton from "../../../../components/buttons/elevatedButton/BaseElevatedButton";
import { useAppSelector } from "../../../../stores/hooks";

interface ProfileFollowButtonProps {
  onFollowToggled: () => void;
}

const ProfileFollowButton: React.FC<ProfileFollowButtonProps> = ({ onFollowToggled }) => {
  const { isFollowed } = useAppSelector((state) => state.profile);
  return (
    <div>
      {isFollowed ? (
        <BaseOutlinedButton onClick={onFollowToggled}>Takibi bırak</BaseOutlinedButton>
      ) : (
        <BaseElevatedButton onClick={onFollowToggled}>Takip Et</BaseElevatedButton>
      )}
    </div>
  );
};

export default ProfileFollowButton;
