import { ResultFuture } from "../../../../core/types/types";
import { UserModel } from "../../../../product/models/user/userModel";
import { IProfileRepository } from "../../domain/repositories/IProfileRepository";
import type { IProfileRemoteDataSource } from "../dataSources/IProfileRemoteDataSource";
import { Left, Right } from "../../../../core/types/either";
import { ApiException } from "next-netkit";
import { ApiFailure } from "../../../../core/error/failure";
import { GetProfileParams } from "../../domain/useCases/GetUserProfile";
import { inject, injectable } from "inversify";
import { ProfileContainerTypes } from "../../../../product/di_containers/containerTypes/profileContainerTypes";
import { NewsModel } from "../../../../product/models/news/newsModel";
import { plainToInstance } from "class-transformer";

@injectable()
export class ProfileRepository implements IProfileRepository {
  @inject(ProfileContainerTypes.IProfileRemoteDataSource)
  private remoteDataSource!: IProfileRemoteDataSource;

  async getProfile(params: GetProfileParams): ResultFuture<UserModel> {
    if (!params.localUser && !params.userId) {
      return new Left(new ApiFailure({ message: "userId is required" }));
    }

    try {
      const user = params.localUser
        ? await this.remoteDataSource.getCurrentProfile()
        : await this.remoteDataSource.getProfile(params.userId!);

      const userModel = plainToInstance(UserModel, user);
      return new Right(userModel);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async getNewsByUserId(userId: number, page: number): ResultFuture<NewsModel[]> {
    try {
      const news = await this.remoteDataSource.getNewsByUserId(userId, page);
      return new Right(news);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async followUser(userId: number | undefined): ResultFuture<void> {
    try {
      if (!userId) {
        return new Left(new ApiFailure({ message: "userId is required" }));
      }
      await this.remoteDataSource.followUser(userId);
      return new Right(undefined);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async unfollowUser(userId: number | undefined): ResultFuture<void> {
    try {
      if (!userId) {
        return new Left(new ApiFailure({ message: "userId is required" }));
      }
      await this.remoteDataSource.unfollowUser(userId);
      return new Right(undefined);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async getFollowersByUserId(userId: number, page: number): ResultFuture<UserModel[]> {
    try {
      const followers = await this.remoteDataSource.getFollowersByUserId(userId, page);
      return new Right(followers);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async getFollowingsByUserId(userId: number, page: number): ResultFuture<UserModel[]> {
    try {
      const followings = await this.remoteDataSource.getFollowingsByUserId(userId, page);
      return new Right(followings);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async removeUserImage(): ResultFuture<void> {
    try {
      await this.remoteDataSource.removeUserImage();
      return new Right(undefined);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async getBookmarkedNews(params: { page: number }): ResultFuture<NewsModel[]> {
    try {
      const news = await this.remoteDataSource.getBookmarkedNews(params.page);
      return new Right(news);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async suggestedUsersToFollow(): ResultFuture<UserModel[]> {
    try {
      const fetchedUsers = await this.remoteDataSource.suggestedUsersToFollow();

      const users = plainToInstance(UserModel, fetchedUsers);
      return new Right(users);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }
}
