import React, { ButtonHTMLAttributes } from "react";
import { useNavigate } from "react-router-dom";
import "./BaseOutlinedButton.css";

interface BaseOutlinedButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  onClick?: () => void;
  to?: string;
  href?: string;
}

const allowedUrls = [
  /^\/[a-zA-Z0-9-_/]*$/, // Internal paths
  /^https:\/\/([a-zA-Z0-9-]+\.)?jurnalle\.com\/[a-zA-Z0-9-_/]*$/, // External URLs including
  // subdomains
];

const isValidUrl = (url: string) => {
  return allowedUrls.some((pattern) => pattern.test(url));
};

const BaseOutlinedButton: React.FC<BaseOutlinedButtonProps> = ({ children, onClick, href, to, ...props }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      // Call the onClick handler if provided
      onClick();
    } else if (href && isValidUrl(href)) {
      // Redirect to external link (open in new tab)
      window.open(href, "_blank");
    } else if (to) {
      // Navigate to internal route
      navigate(to);
    }
  };
  return (
    <button type='button' className='base-outlined-button' onClick={handleClick} {...props}>
      {children}
    </button>
  );
};

export default BaseOutlinedButton;
