import React from "react";
import { useNavigate } from "react-router-dom";
import "./BaseTextButton.css";

interface BaseTextButtonProps {
  children: React.ReactNode;
  to?: string; // Can be an internal path or external URL
  isExternal?: boolean; // Optional flag to indicate external link
  onClick?: () => void; // Optional custom click handler
  isPrimary?: boolean; // Optional prop for primary color
}

const allowedUrls = [
  /^\/[a-zA-Z0-9-_/]*$/, // Internal paths
  /^https:\/\/([a-zA-Z0-9-]+\.)?jurnalle\.com\/[a-zA-Z0-9-_/]*$/, // External URLs including
  // subdomains
];

const isValidUrl = (url: string) => {
  return allowedUrls.some((pattern) => pattern.test(url));
};

const BaseTextButton: React.FC<BaseTextButtonProps> = ({
  children,
  to,
  isExternal = false, // Default to internal link
  onClick,
  isPrimary = false, // Default to false
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (to && isValidUrl(to)) {
      if (isExternal) {
        // Open external link in a new tab
        window.open(to, "_blank");
      } else {
        // Navigate to internal route
        navigate(to);
      }
    }
  };

  return (
    <button
      className='base-text-button'
      onClick={handleClick}
      style={{ color: isPrimary ? "var(--primary-color)" : "inherit" }}
    >
      {children}
    </button>
  );
};

export default BaseTextButton;
