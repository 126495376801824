import "reflect-metadata";
import { inject, injectable } from "inversify";
import { IAuthRepository } from "../../domain/repositories/auth.repository.interface";
import { SignInDto } from "../dto/sign-in.dto";
import { ApiFailure } from "../../../../core/error/failure";
import { ResultFutureVoid } from "../../../../core/types/types";
import { Left, Right } from "../../../../core/types/either";
import { ApiException } from "next-netkit";
import type { IAuthLocalDataSource } from "../data_sources/auth.local.data-source.interface";
import type { IAuthRemoteDataSource } from "../data_sources/auth.remote.data-source.interface";
import { AuthContainerTypes } from "../../../../product/di_containers/containerTypes/autContainerTypes";

@injectable()
export class AuthRepository implements IAuthRepository {
  @inject(AuthContainerTypes.IAuthLocalDataSource)
  public localDataSource!: IAuthLocalDataSource;
  @inject(AuthContainerTypes.IAuthRemoteDataSource)
  private remoteDataSource!: IAuthRemoteDataSource;

  async signIn(dto: SignInDto): ResultFutureVoid {
    try {
      const response = await this.remoteDataSource.signIn(dto);
      this.localDataSource.saveUser(response.user);
      return new Right(undefined);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async isAuthenticated(): ResultFutureVoid {
    try {
      await this.remoteDataSource.checkAuthenticated();
      return new Right(undefined);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async removeAccount(credentials: SignInDto): ResultFutureVoid {
    try {
      await this.remoteDataSource.removeAccount(credentials);
      return new Right(undefined);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }
}
