import { inject } from "inversify";
import { ProfileContainerTypes } from "../../../../product/di_containers/containerTypes/profileContainerTypes";
import type { IProfileRepository } from "../repositories/IProfileRepository";
import { ResultFuture } from "../../../../core/types/types";

export class RemoveUserImage {
  @inject(ProfileContainerTypes.IProfileRepository)
  private profileRepository!: IProfileRepository;

  async execute(): ResultFuture<void> {
    return this.profileRepository.removeUserImage();
  }
}
