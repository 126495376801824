
import React, {  useState } from "react";
import locationList from "../components/AllLocationsList";
interface LocationPopupContentProps {
  selectedLocationId: number | null;
  handleLocationClick: (id: number) => void;
  closePopups: () => void;
}

const LocationPopupContent: React.FC<LocationPopupContentProps> = ({ selectedLocationId , handleLocationClick  , closePopups}) => {

  const nonSelectedPoint: string =
  require("../../../../product/assets/icons/non-seleccted-point.svg").default;
  const selectedPoint: string =
  require("../../../../product/assets/icons/selected-point.svg").default;
  const locationFill: string =
  require("../../../../product/assets/icons/location-fill.svg").default;

  const cancelIco: string =
    require("../../../../product/assets/icons/cance.svg").default;
    const searchIco: string =
    require("../../../../product/assets/icons/search.svg").default;


    const [searchQuery, setSearchQuery] = useState<string>("");

  const filteredLocations = locationList.filter((location) =>
    location.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

return(

  <div className="choose-categories">
  <div className="select-cats">
    <div className="categories-slct">
      <div className="pop-heading">
        <div className="left-poped">
          <h4>Konum</h4>
          <span> Gönderinin hangi konumla ilgili? </span>
        </div>

        <div className="right-poped">
          <img src={cancelIco} alt="ico " onClick={closePopups } />
        </div>
      </div>

      <div className="input-search-cat">
      <img src={searchIco} alt="icon" />
        <input className="search-cat"       value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)} 
      />
      </div>

      <div className="select-cities">
        {filteredLocations.map((location) => (
          <div
            key={location.id}
            className={`cities-list location-item ${
              selectedLocationId === location.id ? "selected" : ""
            }`}
            onClick={() => handleLocationClick(location.id)}
          >
            <div className="cities-cnt">
              <div className="flex-cnt">
                
                <img src={locationFill} alt="icon" /> {location.name}
              </div>

              <div>
                
                <img
                  src={
                    selectedLocationId === location.id
                      ? selectedPoint
                      : nonSelectedPoint
                  }
                  alt="icon"
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="add-cats">
        <button onClick={closePopups }  >Ekle</button>
      </div>
    </div>
  </div>
</div>

)
}

export default LocationPopupContent